/**
 * Вспомогательные функции для заказа
 */
export default  {
  data() {
    return {
    }
  },
  methods: {
    /**
     * Метка типа
     * @param string type 
     * @returns 
     */
    getTypeLabel(type) {
      switch(type) {
        case 'acceptance': return 'Приемка';
        case 'delivery': return 'Доставка';
      }
      return 'Доставка';
    },
    /**
     * Метка статуса
     * @param string status 
     * @returns 
     */
    getStatusLabel(status) {
      let statuses = this.$store.getters['registry/getOrderStatuses'];
      switch(status) {
        case 'new': return statuses['new'] ?? 'Новый';
        case 'drive': return statuses['drive'] ?? 'В пути';
        case 'inplace': return statuses['inplace'] ?? 'Приехал';
        case 'transfer': return statuses['transfer'] ?? 'Передача';
        case 'move': return statuses['move'] ?? 'Перенос';
        case 'partial': return statuses['partial'] ?? 'Выполнен частично';
        case 'cancel': return statuses['cancel'] ?? 'Отменен';
        case 'complete': return statuses['complete'] ?? 'Выполнен';
      }
      return 'Неизвестный';
    },
    /**
     * Получаем статус товара
     * @param string status 
     */
    getProductStatusLabel(status) {
      let statuses = this.$store.getters['registry/getReceiptStatuses'];
      switch(status) {
        case 'new': return statuses['new'] ?? 'Новый';
        case 'accepted': return statuses['accepted'] ?? 'Принята';
        case 'given_away': return statuses['given_away'] ?? 'Выдана';
        case 'not_given': return statuses['not_given'] ?? 'Не выдана';
        case 'avoid': return statuses['avoid'] ?? 'Не получена';
        case 'rework': return statuses['rework'] ?? 'Доработка';
        case 'cancel': return statuses['cancel'] ?? 'Отказ';
      }
      return 'Неизвестный';
    },
    /**
     * Получаем статус квитанции
     * @param string status 
     */
     getInvoiceStatusLabel(status) {
      let statuses = this.$store.getters['registry/getReceiptStatuses'];
      switch(status) {
        case 'new': return statuses['new'] ?? 'Новый';
        case 'accepted': return statuses['accepted'] ?? 'Принята';
        case 'given_away': return statuses['given_away'] ?? 'Выдана';
        case 'not_given': return statuses['not_given'] ?? 'Не выдана';
        case 'avoid': return statuses['avoid'] ?? 'Не получена';
        case 'rework': return statuses['rework'] ?? 'Доработка';
      }
      return 'Неизвестный';
    },
    /**
     * Метка статуса
     * @param string status 
     * @returns 
     */
     getStatusColor(status) {
      switch(status) {
        case 'new': return 'success';
        case 'drive': return 'warning';
        case 'cancel': return 'danger';
        case 'move': return 'danger';
        case 'avoid': return 'danger';
        case 'complete': return 'medium';
        case 'partial': return 'medium';
      }
      return 'primary';
    }
  }
}
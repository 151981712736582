<template>
  <div v-if="mapInited && center">
    <yandex-map
        :settings="map.settings"
        :controls="map.controls"
        :scroll-zoom="false"
        :coords="[center.lat, center.lon]"
        :zoom="15"
        @map-was-initialized="setInstance"
    >
      <ymap-marker
          v-for="point in points"
          :key="point.id"
          :marker-id="point.id"
          :coords="[point.address.lat, point.address.lon]"
          :icon="map.markerIcon"
          :balloon="getBaloon(point)"
      >
      </ymap-marker>
    </yandex-map>
  </div>
</template>

<script>

import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps';
import {mapGetters} from "vuex";

const settings = {
  apiKey: 'bd20edea-15ce-4870-8c19-44b63127c423&suggest_apikey=7dc16d7b-e0ee-415f-afe9-e153b7c62e16',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',

}

export default  {
  components: {yandexMap, ymapMarker},
  props: ['slotId', 'isDone'],
  data() {
    return {
      mapInited: false,
      map: {
        instance: false,
        settings: settings,
        controls: ["fullscreenControl", "zoomControl"],
        markerIcon: {
          layout: 'default#imageWithContent',
          imageHref: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMzQwIDUxMiI+CiAgPHBhdGggZmlsbD0iIzAwOEM5QSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMS4wOCAxOTJhMTg1LjUgMTg1LjUgMCAwIDEtLjY4LTE2Qy40IDc4LjggNzYuMzQgMCAxNzAgMGM5My42NyAwIDE2OS42IDc4LjggMTY5LjYgMTc2IDAgNS40LS4yMyAxMC43My0uNjggMTZoLjY4YzAgOTYuMTgtMTY5LjYgMzIwLTE2OS42IDMyMFMuNCAyODguNzEuNCAxOTJoLjY4Wk0xNzAgMjI0YTY2LjAxIDY2LjAxIDAgMCAwIDY3Ljg0LTY0QTY2LjAxIDY2LjAxIDAgMCAwIDE3MCA5NmE2Ni4wMSA2Ni4wMSAwIDAgMC02Ny44NCA2NEE2Ni4wMSA2Ni4wMSAwIDAgMCAxNzAgMjI0WiIvPgo8L3N2Zz4=',
          imageSize: [43, 43],
          imageOffset: [0, 0],
        }
      }
    }
  },
  computed: {
    ...mapGetters("order", {
      getActiveOrders: "getActiveOrders"
    }),
    points() {
      let points = [];
      if (this.getActiveOrders) {
        for (let x in this.getActiveOrders) {
          if (this.getActiveOrders[x].slot.id != this.slotId) continue;
          points.push(this.getActiveOrders[x]);
        }
      }
      return points;
    },
    ...mapGetters("auth", {
      position: "position"
    }),
    center() {
      if (this.position) {
        return this.position;
      }
      if (this.points) {
        for (let x in this.points) {
          return this.points[x].address;
        }
      }

      return {
        lat: 55.7539588,
        lon: 37.619863
      };

    }
  },
  methods: {
      setInstance(map) {
          this.map.instance = map;
          this.checkPosition();
      },
      checkPosition() {
          if (this.position && this.map.instance) {
              this.map.instance.panTo([this.position.lat, this.position.lon], {
                checkZoomRange: true,
                duration: 1000
              });
          }
      },
      getBaloon(order) {
        let baloon = {header: 'Заказ №' + order.id, body: order.address.adress, footer: '<a href="/order/' + order.id + '">Перейти</a>'};
        return baloon;
      }
  },
  async mounted() {
    await loadYmap({ ...settings, debug: false });
    this.mapInited = true;
  },
  beforeUnmount() {
    if (this.map.instance) {
        this.map.instance.destroy();
        this.map.instance = false;
    }
  }
}
</script>

<style >
.ymap-container {
  width: 100%;
  height: calc(100% - 56px);
  position: fixed;
  left: 0;
  bottom: 0;
}

</style>
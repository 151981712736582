<template>
    <div class="order-detail-section" v-if="items">
      <dc-header class="h3 is--gray">Квитанции</dc-header>
      <div class="product-list" v-if="items.length <= 0">
        <div class="is--no-item">
          Нет квитанций в заказе
        </div>
      </div>
      <div class="product-list">
        <div class="product-list__item" v-for="invoice in items" v-bind:key="invoice.id">
          <div class="product-item">
            <div class="product-item__body">
              <div class="product-item__title">{{invoice.number}} <span v-if="invoice.mask">({{invoice.mask}})</span></div>
              <div class="product-item__note" v-if="invoice.isCTM">CTM</div>
              <div class="product-item__note">{{invoice.comment}}</div>
            </div>
            <div class="product-item__status">
              <dc-badge :color="invoice.status" >{{getInvoiceStatusLabel(invoice.status)}}</dc-badge>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import DcHeader from "@/components/DcHeader";
import OrderHelper from "../mixins/OrderHelper.js";
import DcBadge from "../components/Badge.vue";


export default {
    components: { DcHeader, DcBadge },
    mixins: [OrderHelper],
    props: ['items'],
    methods: {}
}
</script>
<style scoped>
.product-item__status {
  height: 23px;
}
</style>
<template>
  <div class="field">
    <div class="field-label" v-if="label">
      {{label}}
    </div>
    <div class="wrap-field-input">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DcFieldItem",
  props: ['label']
}
</script>

<style >
.field {
  margin: 0 0 var(--dc-indent-2) 0;
  padding: 0;
  position: relative;
}

.field-label,
label:not([class]) {
  margin: 0 0 4px 0;
  padding: 0;
  display: block;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--dc-color-base);
}

.wrap-field-input {
  padding: 0;
  margin:  0;
  width: 100%;
  position: relative;
  display: block;
  vertical-align: middle;
}

.field-input {
  margin: 0;
  padding: 0 var(--dc-indent-1);
  width: 100%;
  height: var(--dc-field-height);
  position: relative;
  line-height: 1;

  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  border: 1px solid var(--dc-color-input-border);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--dc-color-base);
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  appearance: none;
}



.field-input:focus {
  border-color: var(--dc-color-medium-gray);
  outline: none;
}

.field-textarea {
  margin: 0;
  padding: var(--dc-indent-1);
  width: 100%;
  height: 70px;
  position: relative;
  line-height: 1;

  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  border: 1px solid var(--dc-color-input-border);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--dc-color-base);
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  appearance: none;
  resize: none;
}
.field-textarea:focus {
  border-color: var(--dc-color-medium-gray);
  outline: none;
}

</style>
import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { parseError } from "./helpers"

/**
 * Получение списка скидок для квитанции
 */
export const getDiscountsService = async (order, invoice) => {
    try {
        let data = {...invoice};
        data['order'] = {invoices: [...order.invoices]};
        let response = await $api.post(endpoints.discount.get.replace("{orderId}", order.id), data);
        if (response.data.success == true) {
            if (response.data.discounts) {
                return response.data.discounts;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Добавляем скидку к квитанции
 */
 export const addDiscountService = async (order, invoice, discountId, docNumber) => {
    try {
        let data = {...invoice};
        data['order'] = {invoices: [...order.invoices]};
        let response = await $api.post(endpoints.discount.add.replace("{orderId}", order.id), {invoice: data, discount: {id: discountId, docNumber: docNumber}});
        if (response.data.success == true) {
            if (response.data.discounts) {
                return response.data.discounts;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Удаляем скидку у квитанции
 */
export const delDiscountService = async (orderId, invoice, discountId) => {
    try {
        let response = await $api.post(endpoints.discount.del.replace("{orderId}", orderId), {invoice: invoice, discount: {id: discountId}});
        if (response.data.success == true) {
            if (response.data.discounts) {
                return response.data.discounts;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}
<template>
  <base-layout :pageTitle="`Заказ №`+order.id">
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
    <ion-item color="success" v-if="success" class="result-message">
      <ion-label>{{success}}</ion-label>
    </ion-item>
    <ion-item color="danger" v-if="hasSlotAlertMessage" class="result-message">
      <ion-label  class="ion-text-wrap t-center" style="padding: 5rem 0;">{{slotAlertMessage}}</ion-label>
    </ion-item>
    <order-detail :item="order"></order-detail>
    <order-detail-products v-if="order.products" :items="order.products"></order-detail-products>
    <order-detail-invoices v-if="order.invoices" :items="order.invoices"></order-detail-invoices>
    <dc-footer-bar>
      <dc-button shape="border" @click="orderCancel" v-if="order.status!='cancel' && order.status!='complete' && order.status!='move'" >Отмена / Перенос</dc-button>

      <dc-button v-if="order.status=='new'" @click="driveOrder">Принять</dc-button>
      <dc-button v-if="(order.status=='inplace' || order.status=='drive') && order.type=='delivery'" @click="deliveryOrder">Выдача</dc-button>
      <dc-button v-if="(order.status=='inplace' || order.status=='drive') && order.type=='acceptance'" @click="acceptOrder">Приемка</dc-button>
      <dc-button v-if="(order.status=='complete' || order.status=='partial') && order.type=='delivery'" @click="acceptNewOrder">Создать приемку</dc-button>

    </dc-footer-bar>

  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import OrderAction from "../mixins/OrderAction.js";
import OrderDetail from "../components/OrderDetail.vue";
import OrderDetailProducts from "../components/OrderDetailProducts.vue";
import OrderDetailInvoices from "../components/OrderDetailInvoices.vue";
import { IonLabel, IonItem } from '@ionic/vue';
import { closeCircle, checkmark } from 'ionicons/icons';
import { mapActions } from 'vuex'
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";

export default {
    components: { BaseLayout, OrderDetail, IonLabel, IonItem, OrderDetailProducts, OrderDetailInvoices, DcButton, DcFooterBar },
    mixins: [Loading, OrderAction],
    data() {
      return {
        error: '',
        success: '',
        closeCircle: closeCircle,
        checkmark: checkmark,
        lastUpdate: 0,
      }
    },
    methods: {
        ...mapActions('order', {
          driveCurrentOrder: 'driveCurrentOrder',
          inplaceCurrentOrder: 'inplaceCurrentOrder'
        }),
        /**
         * Отмена заказа
         */
        orderCancel() {
          this.$router.push({'path': `/order/cancel/`+this.order.id})
        },
        /**
         * Доставка заказа
         */
        deliveryOrder() {
          this.$router.push({'path': `/order/delivery/`+this.order.id})
        },
        /**
         * Приемка заказа
         */
        acceptOrder() {
          this.$router.push({'path': `/order/accept/`+this.order.id})
        },
        /**
         * Приемка из доставки
         */
        acceptNewOrder() {
          this.$router.push({'path': `/order/newAccept/`+this.order.id})
        },
        /**
         * Заказ поехал
         */        
        driveOrder() {
          this.success = '';
          this.error = '';
          this.driveCurrentOrder()
          .then(() => {
            this.success = "Заказ успешно обновлен";
            setTimeout(() => {
              this.$router.push({path: '/'});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        },
        /**
         * Заказ приехал
         */        
        inplaceOrder() {
          this.success = '';
          this.error = '';
          this.inplaceCurrentOrder()
          .then(() => {
            this.success = "Заказ успешно обновлен";
          })
          .catch(error => {
            this.error = error.message;
          });
        }
    },
    ionViewWillEnter() {
      if (this.lastUpdate>0) {
        this.$router.go(0);
      }
      this.lastUpdate++;
    }
}
</script>


<style>

</style>

<template>
<div>
  <dc-header class="h2">Выберите тип услуги</dc-header>
  <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
    <div 
      class="order-list-item" 
      :class="invoiceItem.type && type.id==invoiceItem.type ? 'selected': ''"
      v-for="type in types" :key="type.id" 
      style="width: 28%; text-align: center;"
      @click="setInvoiceType(type.id)"
    >
      <div class="order-list-item__main">
        <div class="order-list-item__client">
          <img :src="'/assets/images/types/type-' + type.id + '.png'" />
        </div>
        <div class="">
          <div class="order-list-item__id">
            {{type.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <dc-footer-bar>
    <dc-button @click="$emit('back')" shape="border">Назад</dc-button>
    <dc-button v-if="invoiceItem.type" @click="$emit('done')" >Продолжить</dc-button>
    <dc-button v-else :active="false">Продолжить</dc-button>
  </dc-footer-bar>
</div>
</template>

<script>
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcHeader from "@/components/DcHeader";

export default {
    components: {DcButton, DcFooterBar, DcHeader},
    props: ['invoice', 'orderId'],
    data() {
      return {
        invoiceItem: this.invoice
      }
    },
    watch: {
      invoice: function(val) {
        this.invoiceItem = val;
      }
    },
    computed: {
      types: function() {
        return this.$store.getters['registry/getServiceTypes'];
      }
    },
    methods: {
      formatDate (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');
      },
      setInvoiceType(id) {
        let data = {
          invoiceId: this.invoiceItem.id,
          orderId: this.orderId,
          fields: {
            type: id
          }
        }
        if (this.invoiceItem.type && this.invoiceItem.type!=id) {
          data.fields['items'] = [];
          data.fields['discounts'] = [];
          data.fields['total'] = [];
          data.fields['payment'] = [];
        }
        this.$store.dispatch('order/setInvoice', data);
        this.$emit('done');
      }
    },
    mounted() {
    },
}
</script>


<style  >

.order-list-item {
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
  .order-list-item.is--disabled {
    color: var(--dc-color-medium-gray);
  }
  .order-list-item.is--disabled  .order-list-item__time {
    color: var(--dc-color-medium-gray);
  }

.order-list-item__aside {
    margin-right: 16px;
    flex: 0 0 80px;
  }
  .order-list-item-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .order-list-item-type__ico  {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .order-list-item-type__title  {
    font-weight: 500;
  }
  .order-list-item__time {
    color: var(--dc-color-brand);
  }


  .order-list-item__main {
    flex: 0 1 100%;
  }
  .order-list-item__top  {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-item__id {
    font-size: 14px;
    font-weight: 500;
  }
  .order-list-item__status {}
  .order-list-item__title  {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .order-list-item__client {}
  .order-list-item.selected {
    border-color:var(--dc-color-brand);
  }
</style>
import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { parseError } from "./helpers"

/**
 * Запрос на авторизацию
 */
export const loginService = async (request) => {
    try {
        let response = await $api.get(endpoints.auth.login, { 
            params:{
                ...request,
            }
        });
        if (response.data.result == 'success') {
            if (response.data.token) {
                return response.data.token;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * отправка позиции водителя
 */
 export const positionService = async (request) => {
    try {
        await $api.post(endpoints.auth.position, request);
        return;
    } catch(error) {
        throw parseError(error.response);
    }
}

import {loadingController} from '@ionic/vue';
import { mapGetters } from 'vuex'
export default  {
  data() {
    return {
      loading: null,
      isCreating: false,
    }
  },
  computed: {
    ...mapGetters('order',{
      isLoading: 'isLoading'
    })
  },
  methods: {
        /**
         *  Переадресация на форму авторизации
         */
        redirectToLogin() {
          this.$router.push('/login');
        },
        async updateLoading(val) {
          if (this.loading && !val) {
            this.loading.dismiss();
          }
          if (!this.loading && !this.isCreating) {
              this.isCreating = true;
              this.loading = await loadingController.create({});
              this.isCreating = false;
          }
          if (val && this.loading) {
            this.loading.present();
            setTimeout(() => {
              this.loading.dismiss();
            }, 10000);
          }
        },
        async checkLoading() {
          await this.updateLoading(this.isLoading);
        }
  },
  watch: {
    isLoading: function(val) {
      this.updateLoading(val);
    }
  }
}
<template>
  <base-layout :pageTitle="`Приемка. Заказ №`+order.id">
    <dc-header class="h1">Приемка. Заказ №{{order.id}}</dc-header>
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
    <ion-item color="success" v-if="success" class="result-message">
      <ion-label>{{success}}</ion-label>
    </ion-item>
    <ion-item color="danger" v-if="hasSlotAlertMessage" class="result-message">
      <ion-label  class="ion-text-wrap t-center" style="padding: 5rem 0;">{{slotAlertMessage}}</ion-label>
    </ion-item>

    <order-delivery-products v-if="order.products" :items="order.products"></order-delivery-products>
    <order-accept-invoices v-if="order.invoices" :items="order.invoices"></order-accept-invoices>
    <order-delivery-payment></order-delivery-payment>
    <ion-item color="warning" v-if="order && order['slot'] && order.slot.price && order.type=='acceptance'" class="result-message">
      <ion-label>Платный выезд {{order.slot.price}}</ion-label>
    </ion-item>
    <dc-footer-bar>
      <dc-button v-if="(order.status=='inplace' || order.status=='drive') && order.type=='acceptance'" @click="acceptOrder">
<!--        <ion-icon slot="end" :icon="checkmarkOutline"></ion-icon>-->
        Принять
      </dc-button>
    </dc-footer-bar>


  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import OrderAction from "../mixins/OrderAction.js";
import OrderDeliveryProducts from "../components/OrderDeliveryProducts.vue";
import OrderAcceptInvoices from "../components/OrderAcceptInvoices.vue";
import OrderDeliveryPayment from "../components/OrderDeliveryPayment.vue";
import {  IonLabel, IonItem } from '@ionic/vue';
// import { checkmarkOutline } from 'ionicons/icons';
import { mapActions } from 'vuex'
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcHeader from "@/components/DcHeader";

export default {
    components: { BaseLayout,  IonLabel, IonItem, OrderDeliveryProducts, OrderAcceptInvoices, OrderDeliveryPayment, DcButton , DcFooterBar, DcHeader },
    mixins: [Loading, OrderAction],
    data() {
      return {
        error: '',
        success: '',
        // checkmarkOutline: checkmarkOutline
      }
    },
    methods: {
        ...mapActions('order', {
          acceptCurrentOrder: 'acceptCurrentOrder',
          setPremium: 'setPremiumCurrentOrder'
        }),
        /**
         * Заказ принят
         */        
        acceptOrder() {
          this.success = '';
          this.error = '';
          this.acceptCurrentOrder()
          .then(() => {
            this.success = "Заказ успешно принят";
            setTimeout(() => {
              this.$router.push({path: '/'});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        }
    }
}
</script>
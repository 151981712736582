<template>
  <div >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DcHeader",

}
</script>

<style >
.h1 {
  margin: 0 0 24px 0;
  padding: 0;
  font-size:22px;
  font-weight:bolder;
  font-style:normal;
  text-decoration:none;
}
.h2 {
  margin: 0 0 16px 0;
  padding: 0;
  font-size:18px;
  font-weight:bold;
  font-style:normal;
  text-decoration:none;
}
.h3 {
  margin: 0 0 8px 0;
  padding: 0;
  font-size:16px;
  font-weight:bold;
  font-style:normal;
  text-decoration:none;
}
.is--gray {
  color: var(--dc-color-medium-gray);
}

.is--bold {
  font-size:14px;
  font-family:"Roboto";
  font-weight:bold;
  font-style:normal;
  text-decoration:none;
}
</style>
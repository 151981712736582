export default {
  auth: {
    login: `/token`,
    position: `/position`
  },
  orders: {
    statuses: "/orders/active_statuses/",
    timestamp: "/timestamp",
    route: "/route/{date}",
    get: "/order/{orderId}",
    cancel: "/order/cancel/{orderId}",
    move: "/order/move/{orderId}",
    drive: "/order/drive/{orderId}",
    inplace: "/order/inplace/{orderId}",
    accept: "/order/accept/{orderId}",
    delivery: "/order/delivery/{orderId}",
    openShift: "/route/openShift/{date}",
    closeShift: "/route/closeShift/{date}",
    services: "/order/{orderId}/services/{serviceTypeId}/{isPremium}",
    sendRegister: "/route/sendRegister/{date}",
    sendRework: "/route/sendRework/{date}",
  },
  discount: {
    get: "/order/{orderId}/discounts",
    add: "/order/{orderId}/discounts/add",
    del: "/order/{orderId}/discounts/del",
  },
  invoice: {
    calc: "/order/{orderId}/invoice/calc",
    save: "/order/{orderId}/invoice/{invoiceId}/save"
  },
  registry: {
    get: "/registry",
  }
}
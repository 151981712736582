
<template>
<div>
  <div class="product-list">
    <div class="product-list__item" v-for="item in invoiceItem.items" v-bind:key="item.id">
      <label class="checked-button">
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">{{item.name}}</div>
            <div class="product-item__note">{{item.price}} р. X {{item.quantity}}</div>
            <div class="product-item__note">{{item.description.color}}</div>
          </div>
          <div style="text-align: right; font-weight: bold;">
            <span class="_comment" v-if="item.discount && item.discount>0">{{Number(item.amount).toFixed(2)}}&nbsp;р.<br/></span>
            <span class="_neg" v-if="item.discount && item.discount>0"> -&nbsp;{{Number(item.discount).toFixed(2)}}&nbsp;р.<br/></span>
            <span class="_pos">{{Number(item.total).toFixed(2)}}&nbsp;р.</span>
          </div>
        </div>
      </label>
    </div>
    <div class="product-list__item" v-if="invoiceItem.total">
      <label class="checked-button">
        <div class="product-item" style="border: 0;">
          <div>
            <div class="product-item__title _pos">Итого по квитанции №{{invoiceItem.number}}</div>
          </div>
          <div style="text-align: right; font-weight: bold;">
            <span class="_comment" v-if="invoiceItem.total.discount && invoiceItem.total.discount>0">{{Number(invoiceItem.total.amount).toFixed(2)}}&nbsp;р.<br/></span>
            <span class="_neg" v-if="invoiceItem.total.discount && invoiceItem.total.discount>0"> -&nbsp;{{Number(invoiceItem.total.discount).toFixed(2)}}&nbsp;р.<br/></span>
            <span class="_pos">{{Number(invoiceItem.total.total).toFixed(2)}}&nbsp;р.</span>
          </div>
        </div>
      </label>
    </div>
  </div>
  <dc-field-item label="Номер квитанции">
    <input class="field-input" v-model="number" />
  </dc-field-item>
  <dc-field-item label="Метка квитанции">
    <input class="field-input" v-model="mask" />
  </dc-field-item>
  <dc-header class="h2 ">Оплата</dc-header>
  <div class="product-item">
    <div class="product-item__body">
      <div class="product-item__title">Способ оплаты</div>
    </div>
    <ion-select v-model="type"  interface="action-sheet" cancel-text="Отмена" cancelText="Отмена" >
      <ion-select-option v-for="(option, code) in paymentTypes" :value="code" :key="code">{{option}}</ion-select-option>
    </ion-select>
  </div>
  <dc-field-item label="Сумма" v-if="type && type!='no'">
    <input class="field-input"  v-model="summ"  type="text" />
  </dc-field-item>
  <ion-item color="danger" v-if="error">
    <ion-label>{{error}}</ion-label>
  </ion-item>

  <dc-footer-bar>
    <dc-button @click="$emit('back')" shape="border">Назад</dc-button>
    <dc-button @click="confirm">Сохранить</dc-button>
  </dc-footer-bar>
</div>
</template>

<script>
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcHeader from "@/components/DcHeader";
import DcFieldItem from "../DcFieldItem.vue";
import Loading from "../../mixins/Loading.js";
import { calcInvoiceService, saveInvoiceService } from "../../api/invoice.js";
import {
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem
} from "@ionic/vue";

export default {
    components: {DcButton, DcFooterBar, DcHeader, DcFieldItem, IonSelect, IonSelectOption, IonLabel, IonItem},
    props: ['invoice', 'orderId', 'paymentType'],
    mixins: [Loading],
    data() {
      return {
        invoiceItem: this.invoice,
        error: '',
        type: this.paymentType,
        mask: this.invoice['mask'],
        number: this.invoice['number'],
        summ: '',
        comment: ''
      }
    },
    computed: {
      paymentTypes() {
        return this.$store.getters['registry/getPaymentTypes'];
      },
      order () {
        let orders = this.$store.getters["order/getRegisterOrders"];
        for(let x in orders) {
          if (orders[x].id == this.orderId) {
            return orders[x];
          }
        }
        return false;
      },
    },
    watch: {
      invoice: function(val) {
        this.invoiceItem = val;
      }
    },
    methods: {
      async confirm() {
        this.error = '';
        if (!this.number) {
          this.error = 'Не указан номер квитанции';
          return;
        }
        if (!this.mask) {
          this.error = 'Вы не указали метку';
          return;
        }
        if (!this.type) {
          this.error = 'Выберите способ оплаты';
          return;
        }
        if (this.type!='no') {
          if (!this.summ) {
            this.error = 'Укажите сумму для оплаты';
            return;
          }
        }
        this.savePayment();
        try {
          await saveInvoiceService(this.order, this.invoiceItem);
          this.$emit('done');
        } catch(e) {
          this.error = e.message;
        }
      },
      savePayment() {
        let data = {
          invoiceId: this.invoiceItem.id,
          orderId: this.orderId,
          fields: {
            payment: {
                type: this.type,
                summ: this.summ
            },
            mask: this.mask,
            number: this.number,
            items: this.invoiceItem.items,
            total: this.invoiceItem.total,
          }
        }
        this.$store.dispatch('order/setInvoice', data);
      },
      async recalcInvoice() {
        this.$store.dispatch("order/setIsLoading", true);
        try {
          let invoice = await calcInvoiceService(this.order, this.invoiceItem);
          this.invoiceItem.total = invoice.total;
          for (let x in invoice.items) {
            let total = invoice.items[x];
            for (let y in this.invoiceItem.items) {
              if (total.id==this.invoiceItem.items[y].id) {
                this.invoiceItem.items[y]['total'] = Number(total.total).toFixed(2);
                this.invoiceItem.items[y]['amount'] = Number(total.amount).toFixed(2);
                this.invoiceItem.items[y]['discount'] = Number(total.discount).toFixed(2);
              }
            }
          }
          this.summ = Number(invoice.total.total).toFixed(2);
          this.savePayment();
        } catch(e) {
          this.error = e.message;
        }
        this.$store.dispatch("order/setIsLoading", false);
      }
    },
    async mounted() {
      await this.recalcInvoice();
    },
}
</script>


<style  >

.order-list-item {
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
  .order-list-item.is--disabled {
    color: var(--dc-color-medium-gray);
  }
  .order-list-item.is--disabled  .order-list-item__time {
    color: var(--dc-color-medium-gray);
  }

.order-list-item__aside {
    margin-right: 16px;
    flex: 0 0 80px;
  }
  .order-list-item-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .order-list-item-type__ico  {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .order-list-item-type__title  {
    font-weight: 500;
  }
  .order-list-item__time {
    color: var(--dc-color-brand);
  }


  .order-list-item__main {
    flex: 0 1 100%;
  }
  .order-list-item__top  {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-item__id {
    font-size: 14px;
    font-weight: 500;
  }
  .order-list-item__status {}
  .order-list-item__title  {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .order-list-item__client {}
  .order-list-item.selected {
    border-color:var(--dc-color-brand);
  }
</style>
<template>
  <span class="badge " v-bind:class="badgeClass">
        <slot></slot>
  </span>
</template>

<script>
export default {
  name: "DcBadge",
  props: ['color'],
  data() {
    return {
      badgeClass: ''
    }
  },
  mounted() {
    this.badgeClass = 'badge_'+this.color;
  },
}
</script>

<style >
  .badge {
    margin: 0;
    padding: 4px 8px;
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid var(--dc-color-medium-gray);
    color: var(--dc-color-medium-gray);
    box-sizing: border-box;
    border-radius: 2px;
  }
  .badge_new,
  .badge_accepted,
  .badge_given_away,
  .badge_succes {
    border-color: var(--dc-color-success);
    color: var(--dc-color-success);
  }
  .badge_warning,
  .badge_rework,
  .badge_warning {
    border-color: var(--dc-color-warning);
    color: var(--dc-color-warning);
  }
  .badge_cancel,
  .badge_move,
  .badge_avoid,
  .badge_not_given,
  .badge_danger {
    border-color: var(--dc-color-danger);
    color: var(--dc-color-danger);
  }
  .badge_complete,
  .badge_partial,
  .badge_info {
    border-color: var(--dc-color-info);
    color: var(--dc-color-info);
  }
</style>

<template>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div>
      <dc-splash-screen v-if="isNoOrders">
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87 78">
            <path fill="currentColor" fill-rule="evenodd" d="M4.6 20.4h51.43l-5.8-15.96H11.85L4.61 20.4Zm53.97 3.54H1.84a1.77 1.77 0 0 1-1.61-2.51L9.1 1.93C9.39 1.3 10.02.9 10.71.9h40.77c.74 0 1.4.46 1.66 1.16l7.05 19.4a1.77 1.77 0 0 1-1.62 2.48Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M30.2 23.94c-.97 0-1.76-.8-1.76-1.78V2.67a1.77 1.77 0 1 1 3.54 0v19.5c0 .97-.8 1.77-1.77 1.77Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M3.63 73.56h45.7a24.81 24.81 0 0 1 7.47-45.5v-4.12H3.62v49.62Zm54.93 3.54H1.86c-.99 0-1.78-.8-1.78-1.77V22.16c0-.98.8-1.77 1.77-1.77h56.71c.98 0 1.78.8 1.78 1.77v7.36c0 .88-.64 1.62-1.5 1.75a21.4 21.4 0 0 0-18 21.02 21.4 21.4 0 0 0 18 21.02c.86.13 1.5.88 1.5 1.75v.27c0 .98-.8 1.77-1.78 1.77Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M62.1 31.02A21.3 21.3 0 0 0 40.85 52.3a21.3 21.3 0 0 0 21.27 21.27 21.29 21.29 0 0 0 21.26-21.27 21.29 21.29 0 0 0-21.26-21.27Zm0 46.08a24.84 24.84 0 0 1-24.8-24.8 24.84 24.84 0 0 1 24.8-24.82 24.84 24.84 0 0 1 24.82 24.81A24.84 24.84 0 0 1 62.1 77.1Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M70.97 54.06H62.1c-.98 0-1.77-.79-1.77-1.77V38.11a1.77 1.77 0 1 1 3.54 0v12.4h7.09a1.77 1.77 0 0 1 0 3.55Z" clip-rule="evenodd"/>
          </svg>
        </template>
        <template v-slot:text>
          Заказов на сегодня не найдено
        </template>
      </dc-splash-screen>


        <div class="slot-list-item">
          <div class="wrap-slot-list-item-title">
            <div class="slot-list-item__title">
              Заказы Aб/орг и VIP клиентов
            </div>
            <span>
              <a href="javascript:void(0);" @click="$router.push(`/map/special`)" class="is--link">На карту</a>
            </span>
          </div>
          <div class="slot-list-item__body">
            <div v-for="order in orders" :key="order.id" :class="{'is--disabled': !order.active, 'is--new': order.status=='new'}" @click="$router.push(`/order/`+order.id)">
              <order-list-item :orderItem="order"></order-list-item>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import {
   IonRefresher, IonRefresherContent
} from "@ionic/vue";

import OrderHelper from "../mixins/OrderHelper.js";
import OrderListItem from "./OrderListItem.vue";
import Loading from "../mixins/Loading.js";
import { mapActions } from 'vuex';
import DcSplashScreen from "@/components/DcSplashScreen";
import endpoints from "@/api/endpoints";
import {$api} from "@/plugins/Axios";

export default {
    components: { OrderListItem, IonRefresher, IonRefresherContent, DcSplashScreen},
    mixins: [OrderHelper, Loading],
    computed: {
      orders() {
        let orders = this.$store.getters['order/getSortedSpecialOrders'];
        orders.sort(function(a) {
          // Compare the 2 dates
          if (a.status=='new') return -1;
          if (a.status!='new') return 1;
          return 0;
        });
        return orders;
      },
      isNoOrders() {
          return !this.orders || this.orders.length==0;
      }
    },
    methods: {
        ...mapActions('order', {
            fetchOrders: 'fetchOrders'
        }),
        doRefresh(event) {
          this.fetchData();
          setTimeout(() => {
            event.target.complete();
          }, 2000);
        },
        async fetchData() {
          let response = await $api.get(endpoints.orders.timestamp);
          console.log(response.data.result);
          if(response.data.result) {
            this.fetchOrders(new Date(response.data.result * 1000)).catch(error => {
              if(error.code == 401) {
                this.$router.replace({path: '/login'});
              } else {
                setTimeout(() => {
                  this.$router.push({path: '/internetError'});
                }, 500);
              }
            })
          }
        }
    },
    mounted() {
      this.fetchData();
      this.checkLoading();
    }
}
</script>


<style >
.slot-list-item {
  margin-bottom: var(--dc-indent-3);
}
.wrap-slot-list-item-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.slot-list-item__title {
  font-size: 16px;
  font-weight: bold;
  color: var(--dc-color-brand)
}
.is--disabled .slot-list-item__title {
  color: var(--dc-color-medium-gray);
}
.slot-list-item__body {}
.is--new .order-list-item {
  background-color: #ffeeee;
  border-color: var(--brand-color-red);
}
</style>
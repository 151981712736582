<template>
    <div class="order-detail-section" v-if="invoices">
        <dc-header class="h2">Квитанции</dc-header>
        <div class="product-list" v-if="invoices.length <= 0">
            <div class="is--no-item">
                Нет квитанций в заказе
            </div>
        </div>
        <div class="product-list">
            <div class="product-list__item" v-for="invoice in invoices" v-bind:key="invoice.id">
                <label class="checked-button">
                    <div class="product-item">
                        <div class="product-item__body">
                            <div class="product-item__title">{{ invoice.number }}</div>
                            <div class="product-item__note">{{ invoice.comment }}</div>
                        </div>
                        <ion-select :interfaceOptions="{header: '№' + invoice.number, }" cancelText="Отмена"
                                    :value="giveStatus(invoice.status)"
                                    @ionChange="changeInvoice(invoice.id, $event.detail.value)"
                                    interface="action-sheet">
                            <ion-select-option v-for="(option, code) in statuses" :value="code" :key="code">
                                {{ option }}
                            </ion-select-option>
                        </ion-select>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>


<script>
import {IonSelect, IonSelectOption} from '@ionic/vue';
import {mapActions, mapGetters} from "vuex";
import DcHeader from "@/components/DcHeader";


export default {
    components: {IonSelect, IonSelectOption, DcHeader},
    props: ['items'],
    computed: {
        statuses() {
            let statuses = this.$store.getters['registry/getReceiptStatuses'];
            return {
                'not_given': statuses['not_given'] ?? 'Не выдана',
                'rework': statuses['rework'] ?? 'Доработка',
                'given_away': statuses['given_away'] ?? 'Выдана',
                'avoid': statuses['avoid'] ?? 'Не получена',
            };
        },
        ...mapGetters("order", {
            invoices: "getCurrentOrderInvoices"
        }),
    },
    data() {},
    methods: {
        ...mapActions("order", {
            invoiceStatus: "invoiceStatus"
        }),
        /**
         * Изменяем статус продукта
         */
        changeInvoice(id, status) {
            this.invoiceStatus({id: id, status: status});
        },
        giveStatus(status) {
            if(status == 'new') return 'not_given';
            return status;
        }
    }
}
</script>
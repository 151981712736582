
<template>
    <div>
      <div v-for="action in actions" :key="action.id" @click="$router.push(`/actions/`+action.id)">
        <action-list-item :action-item="action"></action-list-item>
      </div>
    </div>
</template>

<script>
import ActionListItem from "./ActionListItem.vue";

export default {
    components: { ActionListItem },
    mixins: [],
    computed: {
      actions() {
        return this.$store.getters['registry/getActions']
      }
    },
    methods: {},
    mounted() {}
}
</script>


<style >
.slot-list-item {
  margin-bottom: var(--dc-indent-3);
}
.wrap-slot-list-item-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.slot-list-item__title {
  font-size: 16px;
  font-weight: bold;
  color: var(--dc-color-brand)
}
.is--disabled .slot-list-item__title {
  color: var(--dc-color-medium-gray);
}
.slot-list-item__body {}
</style>
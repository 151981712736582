<template>
  <base-layout pageTitle="Закрытие смены" >
    <dc-header class="h1">Закрытие смены</dc-header>
    <div class="order-detail-section" >
      <dc-header class="h2">СТМ</dc-header>

      <div class="product-list">
        <div class="product-list__item" v-for="product in products" :key="product.id">
          <label class="checked-button">
            <input class="checked-button-input" type="checkbox" v-model="product.checked"  v-if="product.status!='given_away'">
            <div class="product-item">
              <div class="product-item__body">
                <div class="product-item__title">{{product.product}}</div>
                <div class="product-item__note">({{product.number}}) {{product.comment}}</div>
              </div>
              <div class="product-item__status" :style="product.status=='given_away'?'right: 10px;':''">
                <dc-badge :color="product.status" >{{getInvoiceStatusLabel(product.status)}}</dc-badge>
              </div>
            </div>
          </label>
        </div>
      </div>
      <br>
      <div class="dc-feature-list">
        <div class="dc-feature-list-item" >
          <div class="dc-feature-list-item__title">Всего</div>
          <div class="dc-feature-list-item__val">{{productsCheckedCount}} / {{productsCount}}</div>
        </div>
      </div>
    </div>

    <div class="order-detail-section" >
      <dc-header class="h2">Квитанции</dc-header>

      <div class="product-list">
        <div class="product-list__item" v-for="invoice in invoices" :key="invoice.id">
          <label class="checked-button">
            <input class="checked-button-input" type="checkbox" v-model="invoice.checked" v-if="invoice.status!='given_away' && invoice.status!='avoid'">
            <div class="product-item">
              <div class="product-item__body">
                <div class="product-item__title">{{invoice.number}}</div>
                <div class="product-item__note">{{invoice.comment}}</div>
              </div>
              <div class="product-item__status" :style="(invoice.status=='given_away' || invoice.status=='avoid')?'right: 10px;':''">
                <dc-badge :color="invoice.status" >{{getInvoiceStatusLabel(invoice.status)}}</dc-badge>
              </div>
            </div>
          </label>
        </div>
      </div>
      <br>
      <div class="dc-feature-list">
        <div class="dc-feature-list-item" >
          <div class="dc-feature-list-item__title">Всего</div>
          <div class="dc-feature-list-item__val">{{invoicesCheckedCount}} / {{invoicesCount}}</div>
        </div>
      </div>
    </div>

    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label class="ion-text-wrap">{{error}}</ion-label>
    </ion-item>

    <dc-footer-bar>
      <dc-button @click="closeShift">Закрыть смену</dc-button>
    </dc-footer-bar>
  </base-layout>
</template>

<script>

import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import DcHeader from "@/components/DcHeader";
import DcFooterBar from "@/components/DcFooterBar";
import DcButton from "@/components/DcButton";
import { IonItem, IonLabel } from "@ionic/vue"
import OrderHelper from "../mixins/OrderHelper.js";
import DcBadge from "../components/Badge.vue";

export default  {
  components: {BaseLayout, DcHeader, DcFooterBar, DcButton, IonLabel, IonItem, DcBadge},
  mixins: [Loading, OrderHelper],
  data() {
    return {
      invoices: [],
      products: [],
      error: ''
    }
  },
  computed: {
    invoicesCount () {
      return this.invoices.filter(item => item.status!='given_away' && item.status!='avoid').length;
    },
    productsCount () {
      return this.products.filter(item => item.status!='given_away').length;
    },
    invoicesCheckedCount () {
      return this.invoices.filter(item => item.checked).length;
    },
    productsCheckedCount () {
      return this.products.filter(item => item.checked).length;
    }
  },
  methods: {
    async closeShift() {
      let data = {
        invoices: [],
        products: []
      }
      for (let x of this.invoices) {
        data.invoices.push({id: x.id, checked: !!x.checked});
      }
      for (let x of this.products) {
        data.products.push({id: x.id, checked: !!x.checked});
      }
      this.error = '';
      try {
        await this.$store.dispatch("order/shiftClose", data);
        this.$router.replace({path: '/'});
      } catch (error) {
        this.error = error.message;
      }
    }
  },
  mounted() {
    this.invoices = this.$store.getters["order/getShiftInvoices"];
    this.products = this.$store.getters["order/getShiftProducts"];
  },
  ionViewDidLeave() {
    this.error = '';
  }
}
</script>

<style >

.is--disabled {
  color: var(--dc-color-medium-gray);
}
.product-item__status {
    right: 50px;
    position: absolute;
}
</style>
<template>
  <div class="dc-feature-list">
    <div class="dc-feature-list-item" v-for="(feature, index) in features" :key="index">
      <div class="dc-feature-list-item__title">{{feature.title}}</div>
      <div class="dc-feature-list-item__val" >{{feature.val}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DcFeatureList",
  props: ['items'],
  data() {
    return {
      features: []
    }
  },
  methods: {
  },
  mounted() {
    this.features = this.items;
  },
}
</script>

<style >
.dc-feature-list {}
.dc-feature-list-item {
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}
.dc-feature-list-item__title {}
.dc-feature-list-item__val {
  font-weight: 500;
}
</style>
import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { parseError, formatDate } from "./helpers"

/**
 * Получение списка заказов
 */
export const getRouteService = async (date) => {
    try {
        let response = await $api.get(endpoints.orders.route.replace("{date}", formatDate(date)));
        if (response.data.result == 'success') {
            if (response.data.orders) {
                return {
                    orders: response.data.orders,
                    specialOrders: response.data.specialOrders,
                    shiftStatus: response.data.shift,
                    routeNum: response.data.routeNum,
                    driverId: response.data.driverId,
                };
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Получение заказа
 */
export const getOrderService = async (orderId) => {
    try {
        let response = await $api.get(endpoints.orders.get.replace("{orderId}", orderId));
        if (response.data.result == 'success') {
            if (response.data.order) {
                return response.data.order;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Отмена заказа
 */
 export const cancelOrderService = async (orderId, request) => {
    try {
        let response = await $api.put(endpoints.orders.cancel.replace("{orderId}", orderId), request);
        if (response.data.result == 'success') {
            if (response.data.order) {
                return response.data.order;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Отмена заказа
 */
 export const moveOrderService = async (orderId, request) => {
    try {
        let response = await $api.put(endpoints.orders.move.replace("{orderId}", orderId), request);
        if (response.data.result == 'success') {
            if (response.data.order) {
                return response.data.order;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Заказ в пути
 */
 export const driveOrderService = async (orderId) => {
    try {
        let response = await $api.put(endpoints.orders.drive.replace("{orderId}", orderId));
        if (response.data.result == 'success') {
            if (response.data.order) {
                return response.data.order;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Заказ приехал
 */
 export const inplaceOrderService = async (orderId) => {
   try {
       let response = await $api.put(endpoints.orders.inplace.replace("{orderId}", orderId));
       if (response.data.result == 'success') {
           if (response.data.order) {
               return response.data.order;
           }
       }
       throw parseError(response);
   } catch(error) {
       throw parseError(error.response);
   }
}

/**
 * Доставка заказа
 */
 export const deliveryOrderService = async (orderId, request) => {
    try {
        let response = await $api.put(endpoints.orders.delivery.replace("{orderId}", orderId), request);
        if (response.data.result == 'success') {
            if (response.data.order) {
                return response.data.order;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Приемка заказа
 */
 export const acceptOrderService = async (orderId, request) => {
    try {
        let response = await $api.put(endpoints.orders.accept.replace("{orderId}", orderId), request);
        if (response.data.result == 'success') {
            if (response.data.order) {
                return response.data.order;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Открытие смены
 */
export const openShiftService = async (date, request) => {
    try {
        let response = await $api.post(endpoints.orders.openShift.replace("{date}", formatDate(date)), request);
        if (response.data.result == 'success') {
            if (response.data.shift) {
                return response.data.shift;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Закрытие смены
 */
 export const closeShiftService = async (date, request) => {
    try {
        let response = await $api.post(endpoints.orders.closeShift.replace("{date}", formatDate(date)), request);
        if (response.data.result == 'success') {
            if (response.data.shift) {
                return response.data.shift;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Отправка реестра приемки
 */
 export const sendRegisterService = async (date, request) => {
    try {
        let response = await $api.post(endpoints.orders.sendRegister.replace("{date}", formatDate(date)), request);
        if (response.data.result == 'success') {
            return true;
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Отправка реестра доработки
 */
 export const sendReworkService = async (date, request) => {
    try {
        let response = await $api.post(endpoints.orders.sendRework.replace("{date}", formatDate(date)), request);
        if (response.data.result == 'success') {
            return true;
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Получение списка услуг
 */
 export const getServicesService = async (orderId, serviceTypeId, isPremium) => {
    try {
        let response = await $api.get(
                endpoints.orders.services
                .replace("{orderId}", orderId)
                .replace("{serviceTypeId}", serviceTypeId)
                .replace("{isPremium}", isPremium ? 1 : 0)
        );
        if (response.data.success == true) {
            if (response.data.items) {
                return response.data.items;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}
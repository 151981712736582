<template>

  <div class="order-detail-section" >
    <dc-header class="h2 ">Оплата</dc-header>
    <div class="product-item">
      <div class="product-item__body">
        <div class="product-item__title">Способ оплаты</div>
      </div>
      <ion-select v-model="paymentType"  interface="action-sheet" cancel-text="Отмена" cancelText="Отмена" >
        <ion-select-option v-for="(option, code) in paymentTypes" :value="code" :key="code">{{option}}</ion-select-option>
      </ion-select>
    </div>
    <dc-field-item label="Сумма" v-if="paymentType && paymentType!='no'">
      <input class="field-input"  v-model="paymentSumm"  type="text" />
    </dc-field-item>
    <dc-field-item label="Комментарий">
      <textarea  rows="3" v-model="paymentComment" placeholder="Укажите комментарий" class="field-textarea"></textarea>
    </dc-field-item>
  </div>
</template>

<script>
import { IonSelect, IonSelectOption} from '@ionic/vue';
import DcFieldItem from "@/components/DcFieldItem";
import DcHeader from "@/components/DcHeader";

export default {
    components: { IonSelect, IonSelectOption, DcFieldItem, DcHeader },
    data() {
      return {
        paymentTypes: {
          'opp': 'Оплата при получении',
          'cash': 'Наличные',
          'card': 'Картой',
          'abonent': 'по счету АБ / Орг',
        }
      }
    },
    computed: {
      paymentType: {
        get() {
          let payment = this.$store.getters['order/getCurrentOrderPayment'];
          if (!payment.type) payment.type = 'opp';
          return payment.type;
        },
        set(value) {
          let payment = this.$store.getters['order/getCurrentOrderPayment'];
          payment.type = value;
          this.$store.dispatch('order/payment', payment);
        }
      },
      paymentSumm: {
        get() {
          let payment = this.$store.getters['order/getCurrentOrderPayment'];
          return payment.summ;
        },
        set(value) {
          let payment = this.$store.getters['order/getCurrentOrderPayment'];
          payment.summ = value;
          this.$store.dispatch('order/payment', payment);
        }
      },
      paymentComment: {
        get() {
          let payment = this.$store.getters['order/getCurrentOrderPayment'];
          return payment.comment;
        },
        set(value) {
          let payment = this.$store.getters['order/getCurrentOrderPayment'];
          payment.comment = value;
          this.$store.dispatch('order/payment', payment);
        }
      }
    },
    methods: {}
}
</script>
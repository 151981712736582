<template>
  <div class="order-detail-section" v-if="products">
    <dc-header class="h2">СТМ</dc-header>
    <div class="product-list" v-if="products.length <= 0">
      <div class="is--no-item">
        Нет товаров в заказе
      </div>
    </div>
    <div class="product-list">
      <div class="product-list__item" v-for="product in products" v-bind:key="product.id">
        <label class="checked-button">
          <input class="checked-button-input" type="checkbox" :checked="product.status=='given_away'" @change="changeProduct(product.id, $event.target.checked)" >
          <div class="product-item">
            <div class="product-item__body">
              <div class="product-item__title">{{product.product}}</div>
              <div class="product-item__note">({{product.number}}) {{product.comment}}</div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>

<!---->
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import DcHeader from "@/components/DcHeader";


export default {
    components: {DcHeader},
    props: ['items'],
    computed: {
      ...mapGetters("order", {
        products: "getCurrentOrderProducts"
      })
    },
    methods: {
      ...mapActions("order", {
        productStatus: "productStatus"
      }),
      /**
       * Изменяем статус продукта
       */
      changeProduct(id, checked) {
        let status = 'not_given';
        if (checked) {
          status = 'given_away';
        }
        this.productStatus({id: id, status: status});
      }
    },
}
</script>


<style>
.checked-button {

}
.checked-button input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  left: -999999px;
}
.checked-button-input:checked ~ .product-item {
  padding-right: 40px;
  border-color: var(--dc-color-brand);
}
.checked-button-input ~.product-item:after {
  content: '';
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 16px;
  border-radius: 5px;
  border: 1px solid var(--dc-color-input-border);
  transform: translate(0,-50%);
}

.checked-button-input:checked ~ .product-item:after {
  border: 1px solid var(--dc-color-brand);
}

</style>
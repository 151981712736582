<template>
  <base-layout :pageTitle="`Aкция №`+action.id">
    <div class="order-detail-section" >
      <dc-header class="h1 ">{{action.name}}</dc-header>

      <div class="action-item" style="margin-top: 1rem;">
        <div class="action-item__date">
          {{formatDate(action.date_from)}} &mdash; {{formatDate(action.date_to)}}
        </div>
        <div class="action-item__restriction" v-if="action.regions && action.regions.length">
          <span class="action-item__restriction-title">Только<br/>регионы</span>
          <ul>
            <li v-for="item in action.regions" :key="item.id">
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="action-item__restriction" v-if="action.subdivision_types && action.subdivision_types.length">
          <span class="action-item__restriction-title">Только<br/>типы пунктов</span>
          <ul>
            <li v-for="item in action.subdivision_types" :key="item.id">
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="action-item__restriction" v-if="action.subdivisions && action.subdivisions.length">
          <span class="action-item__restriction-title">Только<br/>пункты</span>
          <ul>
            <li v-for="item in action.subdivisions" :key="item.id">
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="action-item__body" v-if="action.full_text">
          {{action.full_text}}
        </div>
        <div class="action-item__body" v-else>
          {{action.short_text}}
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import DcHeader from "@/components/DcHeader";

export default {
    components: { BaseLayout,  DcHeader },
    mixins: [],
    data() {},
    computed: {
      action() {
        let actions = this.$store.getters['registry/getActions'];
        for (let x in actions) {
          if (actions[x].id == this.$route.params['id']) {
            return actions[x];
          }
        }
        return {};
      },
    },
    methods: {
      formatDate (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');
      }
    }
}
</script>

<style scoped>
.action-item__body {
  white-space: pre-line;
}
.action-item__date {
  text-align: right;
  margin-bottom: 0.5rem;
}
.action-item__restriction {
    margin-bottom: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 5%);
    border-radius: 5px;
}
.action-item__restriction .action-item__restriction-title {
  font-weight: bold;
}
.action-item__restriction ul {
  color: var(--brand-color-red);
}
</style>
<template>
  <div class="footer-bar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DcFooterBar"
}
</script>

<style >
.footer-bar {
  margin: 16px -4px 0 -4px;
  display: flex;

}
.footer-bar:last-child {
  //margin-top: 32px;
}
.footer-bar button {
  margin: 0 4px;
}
</style>
<template>
  <ion-page>

    <ion-header v-if="!hideNavBar">
      <ion-toolbar>

        <ion-buttons slot="start">
          <ion-back-button v-if="$route.path!='/'"
              default-href="/"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end">
            <template v-if="isShiftOpened && $route.path=='/'">
              <a href="javascript:void(0)" @click="closeShift" class="dc-button dc-button_border dc-button_small" style="line-height: 32px;" >Закрыть смену</a>
            </template>
          </slot>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-menu-button style="color: var(--dc-color-brand);"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content id="main-content">
      <slot />
    </ion-content>
    <ion-footer>
      <slot name="footer" />
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonFooter,
  IonMenuButton,

} from "@ionic/vue";
import {mapGetters} from "vuex";


export default {
  props: ["pageTitle", "pageDefaultBackLink", "hideNavBar"],
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonBackButton,
    IonButtons,
    IonFooter,
    IonMenuButton,
  },
  computed: {
    ...mapGetters("order", ["getShiftStatus"]),
    isShiftOpened() {
      return this.getShiftStatus === 'opened';
    },
  },
  methods: {
    closeShift() {
      this.$router.push({path: '/register'});
    },
  },
};
</script>


<style>
.auto-page-logo {
  margin: 0 auto 32px auto;
}
  ion-content {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-end: 16px;
    --padding-start: 16px;
  }

</style>
<template>
  <div class="order-detail-section" v-if="items">
    <dc-header class="h3 is--gray">СТМ</dc-header>
    <div class="product-list" v-if="items.length <= 0">
      <div class="is--no-item">
        Нет товаров в заказе
      </div>
    </div>
    <div class="product-list">
      <div class="product-list__item" v-for="product in items" v-bind:key="product.id">
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">{{product.product}}</div>
            <div class="product-item__note">{{product.qty}} шт. <span v-if="product.number">({{product.number}})</span> {{product.comment}}</div>
          </div>
          <div class="product-item__status">
            <dc-badge :color="product.status" >{{getProductStatusLabel(product.status)}}</dc-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { IonList, IonItem, IonListHeader, IonLabel, IonNote } from '@ionic/vue';


import DcHeader from "@/components/DcHeader";
import OrderHelper from "../mixins/OrderHelper.js";
import DcBadge from "../components/Badge.vue";

export default {
    components: { DcHeader, DcBadge },
    props: ['items'],
    mixins: [OrderHelper],
    methods: {}
}
</script>


<style>
.is--no-item {
  font-style: italic;
}

.product-list {}
  .product-list__item {
    margin-top: var(--dc-indent-1);
  }
  .product-list__item .product-item {
    margin-bottom: 0;
  }
  .product-list__item:first-child {
    margin-top: 0;
  }
.product-item {
  padding: var(--dc-indent-1) var(--dc-indent-2);
  margin-bottom: var(--dc-indent-1);
  min-height: 55px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--dc-color-input-border);
  box-sizing: border-box;
  border-radius: 5px;
}
.product-item__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-item__title {
  font-size: 16px;
  font-weight: 500;
}
.product-item__note {
  margin-top: 2px;
  color: var(--dc-color-medium-gray)
}
.product-item__status {
  height: 23px;
}
</style>
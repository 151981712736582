<template>
  <base-layout pageTitle="Ошибка интернет соединения">


    <dc-splash-screen variant="danger">
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 56 56">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h56v56H0z"/>
            <path fill="currentColor" fill-rule="nonzero" d="M28 4a24 24 0 1 1 0 48 24 24 0 0 1 0-48Zm0 3a21 21 0 1 0 0 42 21 21 0 0 0 0-42Zm0 27a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm0-17.5c.83 0 1.5.67 1.5 1.5v11.14a1.5 1.5 0 0 1-3-.14V17.86A1.5 1.5 0 0 1 28 16.5Z"/>
          </g>
        </svg>
      </template>
      <template v-slot:text>
        <dc-header class="h2">Ошибка</dc-header>
        При попытке получить данные произошла ошибка, проверьте интернет соединение и повторите попытку
      </template>
      <template v-slot:button>
        <dc-button @click="$router.push({path: '/'});">Повторить</dc-button>
      </template>

    </dc-splash-screen>

  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";

import DcButton from "../components/DcButton.vue";
import DcHeader from "@/components/DcHeader";
import DcSplashScreen from "@/components/DcSplashScreen";


export default {
  name: "UIPage",
  components: {
    BaseLayout,
    DcButton,
    DcHeader,
    DcSplashScreen
  },
  data() {
    return {}
  }
}
</script>

<style >

</style>
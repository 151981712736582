<template>
  <div>

    
    <ion-buttons slot="end" 
      style="width: 100%; justify-content: space-between; border: 1px solid var(--dc-color-brand); border-radius: var(--dc-radius); padding: 5px;"
      @click="open"
    >
      <span v-if="selectedValue" style="font-size: 1.1rem; font-weight: bold;">
        {{ selectedValue }}
      </span>
      <span v-else style="font-size: 1.1rem; font-weight: bold;">Не выбрано</span>
      <ion-button class="icon-button">
        <ion-icon slot="icon-only" src="/assets/images/search-outline.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-modal :is-open="modalState=='open'" @ionModalDidPresent="opened">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button>
            <ion-icon slot="icon-only" src="/assets/images/search-outline.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
        <input v-model="value" class="field-input" id="searchAutocomplete"/>
        <ion-buttons slot="end">
          <ion-button  @click="modalState='close'">
            <ion-icon slot="icon-only" src="/assets/images/close.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      
      <ion-content class="ion-padding">
        <div class="product-list">
          <div class="product-list__item" v-for="(item, index) in filtered" :key="index" @click="setValue(item.id)">
            <label class="checked-button">
              <div class="product-item" :class="item.id == modelValue ? 'selected' : ''">
                <div class="product-item__body">
                  <div class="product-item__title">{{item.name}}</div>
                  <div class="product-item__note" v-if="item['price']">{{item.price}} руб.</div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </ion-content>
    </ion-modal>
  </div>
</template>

<script>
import {IonModal, IonContent, IonToolbar, IonButtons, IonButton, IonIcon} from "@ionic/vue";

export default {
  name: "DcAutocomplete",
  components: {IonModal, IonContent, IonToolbar, IonButtons, IonButton, IonIcon},
  props: ['list', 'modelValue'],
  data() {
    return {
      value: '',
      items: this.list,
      modalState: 'close'
    }
  },
  watch: {
    list: function(val) {
      this.items = val;
    },
  },
  computed: {
    filtered() {
      let items = [];
      for (let x in this.items) {
        if (this.items[x].name.toLowerCase().indexOf( this.value.toLowerCase() ) >= 0) {
          items.push(this.items[x]);
        }
      }
      return items;
    },
    selectedValue() {
      for (let x in this.items) {
        if (this.items[x].id == this.modelValue) {
          return this.items[x].name;
        }
      }
      return '';
    }
  },
  methods: {
    setValue(val) {
      this.$emit('update:modelValue', val);
      this.modalState = 'close';
    },
    open() {
      this.modalState='open'; 
    },
    opened() {
      this.value = '';
      setTimeout(() => {
        document.getElementById('searchAutocomplete').focus();
      }, 100);
    }
  },
}
</script>

<style >


.field-input {
  margin: 0;
  padding: 0 var(--dc-indent-1);
  width: 100%;
  height: var(--dc-field-height);
  position: relative;
  line-height: 1;

  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  border: 1px solid var(--dc-color-input-border);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--dc-color-base);
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  appearance: none;
}
.field-input:focus {
  border-color: var(--dc-color-medium-gray);
  outline: none;
}
.product-item.selected {
  border-color: var(--dc-color-brand);
}

ion-button.icon-button {
  padding: 0 var(--dc-indent-1);
  height:  var(--dc-field-height);
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: var(--font-roboto);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid var(--dc-color-brand);
  border-radius: var(--dc-radius);
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
  -webkit-appearance: none;
  appearance: none;
  background: var(--dc-color-brand);
  color: #fff;
  --padding-top: 0!important;
  --padding-end: 0!important;
  --padding-bottom: 0!important;
  --padding-start: 0!important;
}
</style>
<template>
  <base-layout pageTitle="UI">

    <dc-splash-screen>
      <template v-slot:icon>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87 78">
          <path fill="currentColor" fill-rule="evenodd" d="M4.6 20.4h51.43l-5.8-15.96H11.85L4.61 20.4Zm53.97 3.54H1.84a1.77 1.77 0 0 1-1.61-2.51L9.1 1.93C9.39 1.3 10.02.9 10.71.9h40.77c.74 0 1.4.46 1.66 1.16l7.05 19.4a1.77 1.77 0 0 1-1.62 2.48Z" clip-rule="evenodd"/>
          <path fill="currentColor" fill-rule="evenodd" d="M30.2 23.94c-.97 0-1.76-.8-1.76-1.78V2.67a1.77 1.77 0 1 1 3.54 0v19.5c0 .97-.8 1.77-1.77 1.77Z" clip-rule="evenodd"/>
          <path fill="currentColor" fill-rule="evenodd" d="M3.63 73.56h45.7a24.81 24.81 0 0 1 7.47-45.5v-4.12H3.62v49.62Zm54.93 3.54H1.86c-.99 0-1.78-.8-1.78-1.77V22.16c0-.98.8-1.77 1.77-1.77h56.71c.98 0 1.78.8 1.78 1.77v7.36c0 .88-.64 1.62-1.5 1.75a21.4 21.4 0 0 0-18 21.02 21.4 21.4 0 0 0 18 21.02c.86.13 1.5.88 1.5 1.75v.27c0 .98-.8 1.77-1.78 1.77Z" clip-rule="evenodd"/>
          <path fill="currentColor" fill-rule="evenodd" d="M62.1 31.02A21.3 21.3 0 0 0 40.85 52.3a21.3 21.3 0 0 0 21.27 21.27 21.29 21.29 0 0 0 21.26-21.27 21.29 21.29 0 0 0-21.26-21.27Zm0 46.08a24.84 24.84 0 0 1-24.8-24.8 24.84 24.84 0 0 1 24.8-24.82 24.84 24.84 0 0 1 24.82 24.81A24.84 24.84 0 0 1 62.1 77.1Z" clip-rule="evenodd"/>
          <path fill="currentColor" fill-rule="evenodd" d="M70.97 54.06H62.1c-.98 0-1.77-.79-1.77-1.77V38.11a1.77 1.77 0 1 1 3.54 0v12.4h7.09a1.77 1.77 0 0 1 0 3.55Z" clip-rule="evenodd"/>
        </svg>
      </template>
      <template v-slot:text>
        Текст
      </template>
      <template v-slot:button>
        <dc-button>Кнопка</dc-button>
      </template>
    </dc-splash-screen>


    <div v-show="false">
      <dc-badge color="succes" >Новый</dc-badge>

      <dc-badge color="danger" >Новый</dc-badge>

      <dc-header class="h1">Заголовок</dc-header>

      <dc-header class="h2">Заголовок</dc-header>

      <dc-header class="h3 is--gray">Заголовок</dc-header>
      <dc-feature-list :items="address"></dc-feature-list>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";

import DcHeader from "../components/DcHeader.vue";
import DcBadge from "../components/Badge.vue";
import DcFeatureList from "../components/DcFeatureList.vue";
import DcSplashScreen from "@/components/DcSplashScreen";
import DcButton from "@/components/DcButton";


export default {
  name: "UIPage",
  components: {
    BaseLayout,
    DcBadge,
    DcHeader,
    DcFeatureList,
    DcSplashScreen,
    DcButton
  },
  data() {
    return {
      address: [{title: 'Дом', val:'12'},{title: 'Квартира', val:'1'},{title: 'Подъезд', val:'1'},{title: 'Домофон', val:'1'}, ]
    }
  }
}
</script>

<style >

</style>
<template>
  <base-layout pageTitle="Реестр приемки" >
    <dc-header class="h1">Реестр приемки</dc-header>
    <div class="order-detail-section" >
      <div class="product-list" v-for="order in orders" :key="order.id">
        <order-register-item :order-item="order" @edit="registerInvoice"></order-register-item>
      </div>
      <br>
      <div class="dc-feature-list">
        <div class="dc-feature-list-item" >
          <div class="dc-feature-list-item__title">Всего</div>
          <div class="dc-feature-list-item__val">{{invoicesCheckedCount}} / {{invoicesCount}}</div>
        </div>
      </div>
    </div>

    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>

    <dc-footer-bar>
      <dc-button @click="saveRegister">Отправить реестр</dc-button>
    </dc-footer-bar>
  </base-layout>
</template>

<script>

import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import DcHeader from "@/components/DcHeader";
import DcFooterBar from "@/components/DcFooterBar";
import DcButton from "@/components/DcButton";
import { IonItem, IonLabel } from "@ionic/vue"
import OrderHelper from "../mixins/OrderHelper.js";
import OrderRegisterItem from "../components/OrderRegisterItem.vue";
import { sendRegisterService } from "../api/order.js";
import {$api} from "@/plugins/Axios";
import endpoints from "@/api/endpoints";

export default  {
  components: {BaseLayout, DcHeader, DcFooterBar, DcButton, IonLabel, IonItem, OrderRegisterItem},
  mixins: [Loading, OrderHelper],
  data() {
    return {
      error: ''
    }
  },
  computed: {
    invoicesCount () {
      let cnt = 0;
      for(let x in this.orders) {
        if (this.orders[x]['invoices']) {
          cnt += this.orders[x].invoices.length;
        }
      }
      return cnt;
    },
    invoicesCheckedCount () {
      let cnt = 0;
      for(let x in this.orders) {
        if (this.orders[x]['invoices']) {
          for(let y in this.orders[x].invoices) {
            if (this.orders[x].invoices[y]['items'] && this.orders[x].invoices[y].items.length > 0) {
              cnt++;
            }
          }
        }
      }
      return cnt;
    },
    orders () {
      return this.$store.getters["order/getRegisterOrders"];
    }
  },
  methods: {
    async saveRegister() {
      this.error = '';
      if (this.invoicesCheckedCount == this.invoicesCount) {
        try {
          let response = await $api.get(endpoints.orders.timestamp);
          console.log(response.data.result);
          if(response.data.result) {
            await sendRegisterService(new Date(response.data.result * 1000), {});
            await this.$router.push({path: '/rework'});
          }
        } catch (error) {
          this.error = error.message;
        }
      } else {
        this.error = 'Вы не заполнили реестр по всем квитанциям';
      }
    },
    registerInvoice(event) {
      this.error = '';
      this.$router.push({path: '/register/' + event.orderId + '/' + event.invoiceId})
    }
  },
  mounted() {
  }
}
</script>

<style >

.is--disabled {
  color: var(--dc-color-medium-gray);
}
.product-item__status {
    right: 50px;
    position: absolute;
}
</style>
<template>
  <button class="dc-button" :class="this.computedClass">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "DcButton",
  props: ['shape', 'size', 'active'],
  computed: {
    computedClass() {
      return [
        {
          'dc-button_border': this.shape === 'border' || this.active === false,
          'dc-button_small' : this.size  === 'small',
          'dc-button_inactive': this.active === false,
        }
      ]
    }
  }
}
</script>

<style >
  .dc-button {
      padding: 0 var(--dc-indent-1);
      height:  var(--dc-field-height);
      width: 100%;
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-family: var(--font-roboto);
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
      //line-height: @btn-line-height;
      color: #fff;
      background: var(--dc-color-brand);
      border: 1px solid var(--dc-color-brand);
      border-radius: var(--dc-radius);
      cursor: pointer;
      box-sizing: border-box;
      text-transform: uppercase;
      -webkit-appearance: none;
      appearance: none;
    }
  .dc-button_small {
    height: 32px;
    font-size: 14px;
    text-transform: none;
  }
  .dc-button_border {
    background: #fff;
    color: var(--dc-color-brand);
  }
  .dc-button_inactive {
    background: #fff;
    color: var(--dc-color-gray);
    border-color: var(--dc-color-gray);
  }
</style>
<template>
  <base-layout pageTitle="Начало смены" >
    <dc-header class="h1">Начало смены</dc-header>
    <div class="order-detail-section" >
      <dc-header class="h2">СТМ</dc-header>

      <div class="product-list">
        <div class="product-list__item" v-for="product in products" :key="product.id">
          <label class="checked-button">
            <input class="checked-button-input" type="checkbox" v-model="product.checked" >
            <div class="product-item">
              <div class="product-item__body">
                <div class="product-item__title">{{product.product}} - {{product.qty}} шт.</div>
                <div class="product-item__note">({{product.number}}) {{product.comment}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <br>
      <div class="dc-feature-list">
        <div class="dc-feature-list-item" >
          <div class="dc-feature-list-item__title">Всего</div>
          <div class="dc-feature-list-item__val">{{productsCheckedCount}} / {{productsCount}}</div>
        </div>
      </div>
    </div>

    <div class="order-detail-section" >
      <dc-header class="h2">Квитанции</dc-header>

      <div class="product-list">
        <div class="product-list__item" v-for="invoice in invoices" :key="invoice.id">
          <label class="checked-button">
            <input class="checked-button-input" type="checkbox" v-model="invoice.checked">
            <div class="product-item">
              <div class="product-item__body">
                <div class="product-item__title">
                  {{invoice.number}}
                  <span class="_comment">(Заказ №{{invoice.orderId}})</span>
                  <span class="_comment" v-if="invoice.mask">({{invoice.mask}})</span>
                </div>
                <div class="product-item__note">{{invoice.comment}}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <br>
      <div class="dc-feature-list">
        <div class="dc-feature-list-item" >
          <div class="dc-feature-list-item__title">Всего</div>
          <div class="dc-feature-list-item__val">{{invoicesCheckedCount}} / {{invoicesCount}}</div>
        </div>
      </div>
    </div>

    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>

    <dc-footer-bar>
      <dc-button @click="startShift">Начать смену</dc-button>
    </dc-footer-bar>
  </base-layout>
</template>

<script>

import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import DcHeader from "@/components/DcHeader";
import DcFooterBar from "@/components/DcFooterBar";
import DcButton from "@/components/DcButton";
import { IonItem, IonLabel } from "@ionic/vue"

export default  {
  components: {BaseLayout, DcHeader, DcFooterBar, DcButton, IonLabel, IonItem},
  mixins: [Loading],
  data() {
    return {
      invoices: [],
      products: [],
      error: ''
    }
  },
  computed: {
    invoicesCount () {
      return this.invoices.length;
    },
    productsCount () {
      return this.products.length;
    },
    invoicesCheckedCount () {
      return this.invoices.filter(item => item.checked).length;
    },
    productsCheckedCount () {
      return this.products.filter(item => item.checked).length;
    }
  },
  methods: {
    async startShift() {
      let data = {
        invoices: [],
        products: []
      }
      for (let x of this.invoices) {
        data.invoices.push({id: x.id, checked: !!x.checked});
      }
      for (let x of this.products) {
        data.products.push({id: x.id, checked: !!x.checked});
      }
      this.error = '';
      try {
        await this.$store.dispatch("order/shiftOpen", data);
        this.$router.replace({path: '/'});
      } catch (error) {
        this.error = error;
      }
    }
  },
  mounted() {
    this.invoices = this.$store.getters["order/getShiftInvoices"];
    this.products = this.$store.getters["order/getShiftProducts"];
  }
}
</script>

<style >

</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/appVariables.css';

import store from './store';

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router);
  
router.isReady().then(async () => {  
    /* Запускаем приложение */
    await store.dispatch('auth/init');
    await store.dispatch('registry/init');
    app.mount('#app');
});


var defaultOnError = window.onerror;
window.onerror = function sebekon_errorhandler(error, url, line, col, errorObj) {
        error = error + '[' + line + ':' + col + ']' + ' - {{ $userName }}';
        if (errorObj && errorObj.stack) {
            error += "\n" + errorObj.stack.slice(0, 1024);
        }
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.open("GET", process.env.VUE_APP_API_BASE_URL + "/error/js/?error="+encodeURIComponent(error)+"&url="+encodeURIComponent(url)+"&line="+encodeURIComponent(line),true);
  xmlhttp.send();
  if (defaultOnError)
    return defaultOnError(error, url, line);

  return false;
}
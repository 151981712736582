/**
 * Mixin для экранов с изменениями по заказу
 */
import { mapGetters, mapActions } from 'vuex'

export default  {
  data() {
    return {
      orderId: false
    }
  },
  computed: {
      ...mapGetters("order", {
        order: "getCurrentOrder"
      }),
      slotAlertMessage() {
        return this.$store.getters['getSlotsAlert'];
      },
      hasSlotAlertMessage() {
        if (!this.order || !this.order['slot'] || !this.order.slot['sort']) {
          return false;
        }
        if (this.order.status=='complete' || this.order.status=='cancel' 
          || this.order.status=='partial' || this.order.status=='move')
          return false;
        let orders = this.$store.getters['order/getActiveOrders'];
        let slotSort = this.order.slot.sort;
        if (orders.length > 0) {
          for (let x in orders) {
            if (orders[x].slot.sort < slotSort) {
              return true;
            }
          }
        }
        return false;
      }
  },
  methods: {
      ...mapActions('order', {
          fetchOrder: 'fetchOrder'
      })
  },
  mounted() {
      this.orderId = this.$route.params.orderId
      this.fetchOrder(this.orderId);
  }
}

/**
 * Сервис вебсокет соединения
 */
 var WebsocketService = (function () {
    var instance,
      wsConnection;
  
    var heartbeat = function() {
      if(!wsConnection) {
        sendError();
        return;
      }
      if(wsConnection.readyState !== 1) {
        sendError();
        return;
      }
      setTimeout(heartbeat, 1000);
    }
  
    var sendError = function() {
      setTimeout(function() {
        const event = new Event('websocketService.error');
        window.dispatchEvent(event);
      }, 1000);
    }
  
    var connect = function (token, onMessage, onClose) {
      wsConnection = new WebSocket('wss://' + process.env.VUE_APP_WEBSOCKET_DOMAIN + ':' + process.env.VUE_APP_WEBSOCKET_PORT + '?token=' + token + '&channel='+process.env.VUE_APP_WEBSOCKET_CHANNEL);
      wsConnection.onmessage = function(event) {
        if (onMessage) {
            onMessage(event);
        } else {
            console.log(event);
        }
      }
      wsConnection.onerror = function (error) {
        if (onClose) {
            onClose(error);
        } else {
            sendError();
        }
      };
      wsConnection.onclose = function (error) {
        if (onClose) {
            onClose(error);
        } else {
            sendError();
        }
      };
      wsConnection.onopen = function () {
        heartbeat();
      };
    }
  
    var createInstance = function () {
      return {
        connect: connect
      }
    }
  
    return {
      getInstance: function () {
        return instance || (instance = createInstance());
      }
    }
  })();
  
  export {WebsocketService};
  
<template>
  <base-layout :pageTitle="`Выдача. Заказ №`+order.id">
    <dc-header class="h1">Выдача. Заказ №{{order.id}}</dc-header>
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
    <ion-item color="success" v-if="success" class="result-message">
      <ion-label>{{success}}</ion-label>
    </ion-item>
    <ion-item color="danger" v-if="hasSlotAlertMessage" class="result-message">
      <ion-label  class="ion-text-wrap t-center" style="padding: 5rem 0;">{{slotAlertMessage}}</ion-label>
    </ion-item>
    <order-delivery-products v-if="order.products" :items="order.products"></order-delivery-products>
    <order-delivery-invoices v-if="order.invoices" :items="order.invoices"></order-delivery-invoices>
    <order-delivery-payment></order-delivery-payment>

    <div class="product-item" v-if="!isCompleted">
      <div class="product-item__body">
        <div class="product-item__title">Причина</div>
      </div>
      <ion-select  interface="action-sheet" cancel-text="Отмена" v-model="reasonId">
        <ion-select-option v-for="(option, code) in availReasons" :value="code" :key="code">{{option}}</ion-select-option>
      </ion-select>
    </div>

    <dc-footer-bar v-if="(order.status=='inplace' || order.status=='drive') && order.type=='delivery'">
      <dc-button fill="solid" color="primary" v-if="isCompleted" @click="deliveryOrder">
        Выдано
      </dc-button>
      <dc-button fill="solid" color="primary" v-else @click="deliveryPartialOrder">
        Выдано частично
      </dc-button>
    </dc-footer-bar>

  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import OrderAction from "../mixins/OrderAction.js";
import OrderDeliveryProducts from "../components/OrderDeliveryProducts.vue";
import OrderDeliveryInvoices from "../components/OrderDeliveryInvoices.vue";
import OrderDeliveryPayment from "../components/OrderDeliveryPayment.vue";
import {  IonSelect, IonSelectOption, IonLabel, IonItem } from '@ionic/vue';
import { checkmark } from 'ionicons/icons';
import { mapActions } from 'vuex'
import DcFooterBar from "@/components/DcFooterBar";
import DcButton from "@/components/DcButton";
import DcHeader from "@/components/DcHeader";

export default {
    components: { BaseLayout,  IonSelect, IonSelectOption, IonLabel, IonItem, OrderDeliveryProducts, OrderDeliveryInvoices, OrderDeliveryPayment, DcFooterBar, DcButton, DcHeader },
    mixins: [Loading, OrderAction],
    data() {
      return {
        error: '',
        success: '',
        reasonId: '',
        checkmark: checkmark
      }
    },
    methods: {
        ...mapActions('order', {
          deliveryCurrentOrder: 'deliveryCurrentOrder',
          deliveryPartialCurrentOrder: 'deliveryPartialCurrentOrder',
        }),
        /**
         * Заказ выдан
         */        
        deliveryOrder() {
          this.success = '';
          this.error = '';

          this.deliveryCurrentOrder()
          .then(() => {
            this.success = "Заказ успешно выдан";
            setTimeout(() => {
              this.$router.push({path: '/order/' + this.order.id});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        },
        /**
         * Заказ выдан
         */        
        deliveryPartialOrder() {
          this.success = '';
          this.error = '';

          if (!this.reasonId) {
            this.error = 'Вы не выбрали причину частичной выдачи';
            return;
          }

          this.deliveryPartialCurrentOrder({
            reasonId: this.reasonId
          })
          .then(() => {
            this.success = "Заказ выдан частично";
            setTimeout(() => {
              this.$router.push({path: '/'});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        }
    },
    computed: {
      isCompleted() {
        return this.$store.getters['order/isCompletedCurrentOrder']
      },
      availReasons() {
        return this.reasons.partial;
      },
      reasons() {
        return this.$store.getters["registry/getReasons"];
      }
    }
}
</script>
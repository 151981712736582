<template>
  <div class="container" v-if="specialOrdersCnt > 0">
    <ion-segment :value="path" @ionChange="changeMenu">
      <ion-segment-button value="/">
        <ion-label>Заказы (<span>{{ordersCnt}}</span>)</ion-label>
      </ion-segment-button>
      <ion-segment-button value="/abonent">
        <ion-label>Аб/орг (<span>{{specialOrdersCnt}}</span>)</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
</template>

<script>
import {IonSegment, IonSegmentButton, IonLabel} from '@ionic/vue';
export default {
  name: "OrdersMenu",
  components: {
    IonSegment, IonSegmentButton, IonLabel,
  },
  data() {
    return {
    }
  },
  computed: {
    path() {
      return this.$route.path;
    },
    ordersCnt() {
      return this.$store.getters['order/getOrders'].length;
    },
    specialOrdersCnt() {
      return this.$store.getters['order/getSpecialOrders'].length;
    },
  },
  mounted() {
    this.badgeClass = 'badge_'+this.color;
  },
  methods: {
    changeMenu (e) {
      if (e.target.value!=this.value) {
        this.$router.push(e.target.value);
      }
    }
  }
}
</script>

<style >
  .container {
    margin: -1rem -16px 2rem;
    display: flex;
    justify-content:space-between;
  }
  ion-segment-button::part(indicator-background) {
    background: var(--dc-color-info);
  }

  /* Material Design styles */
  ion-segment-button.md::part(native) {
    color: #000;
  }

  .segment-button-checked.md::part(native) {
    color: var(--dc-color-info);
  }

  ion-segment-button.md::part(indicator-background) {
    height: 4px;
  }
</style>
import axios from "axios"

const handleError = async (error) => {
  if (error.response && !error.response.config.url.includes("auth")) {
    if (parseInt(error.response && error.response.status) === 401) {
      //await auth.mutations.logOut()
    }
  }
}

// create instance
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    common: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // "X-Requested-With": "XMLHttpRequest",
    },
  },
})

api.interceptors.request.use(
  (x) => {
    console.log(`${x.method.toUpperCase()} | ${x.url}`, x.params, x.data)
    return x
  },
  async (error) => {
    await handleError(error)
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (x) => {
    console.log(`${x.status} | ${x.config.url}`, x.data)

    return x
  },
  async (error) => {
    await handleError(error)
    return Promise.reject(error)
  }
)

const initAxios = (app) => {
  app.prototype.$api = api
}

const setDefaultAxiosParam = (param, value) => {
  api.defaults.params = api.defaults.params || {}
  api.defaults.params[param] = value
}

const setDefaultAxiosHeader = (param, value) => {
  api.defaults.headers = api.defaults.headers || { common: {} }
  api.defaults.headers.common[param] = value
}

export { initAxios, setDefaultAxiosParam, setDefaultAxiosHeader, api as $api }
<template>
  <base-layout pageTitle="Карта" >
    <orders-map :slot-id="$route.params.slotId" :done="$route.params['done'] && $route.params['done']=='done'"></orders-map>
  </base-layout>
</template>

<script>

import BaseLayout from "../layout/BaseLayout.vue";
import OrdersMap from "../components/OrdersMap.vue";

export default  {
  components: {BaseLayout, OrdersMap},
}
</script>

<template>
<div>
  <div class="product-list">
    <div class="product-list__item" v-for="item in invoiceItem.items" v-bind:key="item.id">
      <label class="checked-button">
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">{{item.name}}</div>
            <div class="product-item__note">{{item.price}} р. X {{item.quantity}}</div>
            <div class="product-item__note">{{item.description.color}}</div>
          </div>
        </div>
      </label>
    </div>
  </div>
  <dc-field-item label="Номер квитанции">
    <input class="field-input" v-model="number" />
  </dc-field-item>
  <dc-field-item label="Метка квитанции">
    <input class="field-input" v-model="mask" />
  </dc-field-item>
  <ion-item color="danger" v-if="error">
    <ion-label>{{error}}</ion-label>
  </ion-item>

  <dc-footer-bar>
    <dc-button @click="$emit('back')" shape="border">Назад</dc-button>
    <dc-button @click="confirm">Сохранить</dc-button>
  </dc-footer-bar>
</div>
</template>

<script>
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcFieldItem from "../DcFieldItem.vue";
import { saveInvoiceService } from "../../api/invoice.js";
import {
  IonLabel,
  IonItem
} from "@ionic/vue";

export default {
    components: {DcButton, DcFooterBar, DcFieldItem, IonLabel, IonItem},
    props: ['invoice', 'orderId', 'paymentType'],
    data() {
      return {
        invoiceItem: this.invoice,
        error: '',
        mask: this.invoice['mask'],
        number: this.invoice['number'],
        comment: ''
      }
    },
    watch: {
      invoice: function(val) {
        this.invoiceItem = val;
      }
    },
    computed: {
      order () {
        let orders = this.$store.getters["order/getReworkOrders"];
        for(let x in orders) {
          if (orders[x].id == this.orderId) {
            return orders[x];
          }
        }
        return false;
      },
    },
    methods: {
      async confirm() {
        this.error = '';
        if (!this.number) {
          this.error = 'Не указан номер квитанции';
          return;
        }
        if (!this.mask) {
          this.error = 'Вы не указали метку';
          return;
        }
        this.saveNumber();
        try {
          await saveInvoiceService(this.order, this.invoiceItem);
          this.$emit('done');
        } catch(e) {
          this.error = e.message;
        }
      },
      saveNumber() {
        let data = {
          invoiceId: this.invoiceItem.id,
          orderId: this.orderId,
          fields: {
            mask: this.mask,
            number: this.number,
            items: this.invoiceItem.items,
          }
        }
        this.$store.dispatch('order/setInvoice', data);
      },
    },
    async mounted() {
    },
}
</script>


<style  >

.order-list-item {
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
  .order-list-item.is--disabled {
    color: var(--dc-color-medium-gray);
  }
  .order-list-item.is--disabled  .order-list-item__time {
    color: var(--dc-color-medium-gray);
  }

.order-list-item__aside {
    margin-right: 16px;
    flex: 0 0 80px;
  }
  .order-list-item-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .order-list-item-type__ico  {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .order-list-item-type__title  {
    font-weight: 500;
  }
  .order-list-item__time {
    color: var(--dc-color-brand);
  }


  .order-list-item__main {
    flex: 0 1 100%;
  }
  .order-list-item__top  {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-item__id {
    font-size: 14px;
    font-weight: 500;
  }
  .order-list-item__status {}
  .order-list-item__title  {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .order-list-item__client {}
  .order-list-item.selected {
    border-color:var(--dc-color-brand);
  }
</style>
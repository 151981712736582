<template>
  <base-layout pageTitle="Реестр приемки" >
    <dc-header class="h1">Квитанция №{{invoice.number}}</dc-header>
    <invoice-premium :invoice="invoice" :order-id="orderId" v-if="step=='premium'" @done="step = 'type'" @back="$router.push({path: '/register'});"></invoice-premium>
    <invoice-types :invoice="invoice" :order-id="orderId" v-if="step=='type'" @done="step = 'items'" @back="step = 'premium'"></invoice-types>
    <invoice-items :invoice="invoice" :order-id="orderId" v-if="step=='items'" @done="step = 'discount'" @back="step = 'type'"></invoice-items>
    <invoice-discounts :invoice="invoice" :order-id="orderId" v-if="step=='discount'" @done="step = 'payment'" @back="step = 'items'"></invoice-discounts>
    <invoice-payment :invoice="invoice" :order-id="orderId" :payment-type="order.payment.type" v-if="step=='payment'" @done="step=='type'; $router.push({path: '/register'})" @back="step = 'discount'"></invoice-payment>
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
  </base-layout>
</template>

<script>

import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import DcHeader from "@/components/DcHeader";
import { IonItem, IonLabel } from "@ionic/vue";
import OrderHelper from "../mixins/OrderHelper.js";
import InvoicePremium from "../components/Checkout/InvoicePremium.vue";
import InvoiceTypes from "../components/Checkout/InvoiceTypes.vue";
import InvoiceItems from "../components/Checkout/InvoiceItems.vue";
import InvoiceDiscounts from "../components/Checkout/InvoiceDiscounts.vue";
import InvoicePayment from "../components/Checkout/InvoicePayment.vue";

export default  {
  components: {BaseLayout, DcHeader, IonLabel, IonItem,
              InvoicePremium,
              InvoiceTypes,
              InvoiceItems,
              InvoiceDiscounts,
              InvoicePayment
              },
  mixins: [Loading, OrderHelper],
  data() {
    return {
      error: '',
      orderId: '',
      invoiceId: '',
      step: 'premium'
    }
  },
  computed: {
    order () {
      let orders = this.$store.getters["order/getRegisterOrders"];
      for(let x in orders) {
        if (orders[x].id == this.orderId) {
          return orders[x];
        }
      }
      return false;
    },
    invoice () {
      if (this.order) {
        for(let x in this.order.invoices) {
          if (this.order.invoices[x].id == this.invoiceId) {
            const invoice = this.order.invoices[x];
            if (invoice.isPremium === undefined) {
              invoice.isPremium = this.order.isPremium;
            }
            return invoice;
          }
        }
      }
      return false;
    }
  },
  methods: {
  },
  mounted() {
    this.invoiceId = this.$route.params['invoiceId'];
    this.orderId = this.$route.params['orderId'];
  }
}
</script>

<style >

.is--disabled {
  color: var(--dc-color-medium-gray);
}
.product-item__status {
    right: 50px;
    position: absolute;
}
</style>
<template>
  <div class="dc-splash" :class="additionClass">
    <div class="dc-splash__icon">
      <slot name="icon"></slot>
    </div>
    <div class="dc-splash__text" v-if="hasTextSlot">
      <slot name="text"></slot>
    </div>
    <div class="dc-splash__button" v-if="hasButtonSlot">
      <slot name="button"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: "DcSplashScreen",
  props: ['variant'],
  computed: {
    additionClass() {
      return (this.variant) ? 'dc-splash__' + this.variant : false
    },
    hasButtonSlot() {
      console.log()
      return !!this.$slots.button
    },
    hasTextSlot() {
      console.log()
      return !!this.$slots.text
    }
  }
}
</script>

<style >
.dc-splash {
  padding: 16px;
  width: 100%;
  height: calc(100% - 0px);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFF;
}
.dc-splash__danger .dc-splash__icon {
  color: var(--dc-color-danger);
}
.dc-splash__success .dc-splash__icon {
  color: var(--dc-color-success);
}

.dc-splash__warning .dc-splash__icon {
  color: var(--dc-color-warning);
}

.dc-splash__icon  {
  width: 100%;
  max-width: 86px;
  text-align: center;
  color: #D3D3D3;
}
.dc-splash__text  {
  margin-top: var(--dc-indent-3);
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--dc-color-base);
  text-align: center;
}
.dc-splash__button {
  margin-top: var(--dc-indent-3);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.dc-splash__button .dc-button {
  margin-left: var(--dc-indent-1);
  margin-right: var(--dc-indent-1);
  max-width: 200px;
}
</style>
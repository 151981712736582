<template>

  <div class="order-detail-section" v-if="invoices">
    <dc-header class="h2">Квитанции</dc-header>

    <div class="product-list">
      <div class="product-list__item" v-for="invoice in invoices" v-bind:key="invoice.id">
        <label class="checked-button">
          <div class="product-item">
            <div class="product-item__body">
              <div class="product-item__title">{{invoice.number}} <span v-if="invoice['mask']">({{invoice.mask}})</span></div>
              <div class="product-item__note" v-if="invoice.isCTM">CTM</div>
              <div class="product-item__note">{{invoice.comment}}</div>
            </div>
            <ion-button @click="removeInvoice(invoice.id)" color="danger">X</ion-button>
          </div>
        </label>
      </div>
      <ion-item color="danger" v-if="error">
        <ion-label>{{error}}</ion-label>
      </ion-item>

      <div class="invoce-accept-add"  v-if="mode=='add'">

          <dc-field-item label="Номер">
            <dc-input :value="newNumber" v-on:input="setNumber"></dc-input>
          </dc-field-item>
        <label class="checked-button">
          <input class="checked-button-input" type="checkbox" v-model="newIsCTM">
          <div class="product-item">
            <div class="product-item__body">
              <div class="product-item__title">
                СТМ
              </div>
            </div>
          </div>
        </label>
          <dc-field-item label="Комментарий">
            <dc-input :value="newComment" v-on:input="setComment"></dc-input>
          </dc-field-item>
          <dc-footer-bar>
            <dc-button size="small" @click="mode=''; error='';" shape="border">Отмена</dc-button>
            <dc-button size="small" @click="saveInvoice">Сохранить</dc-button>
          </dc-footer-bar>

      </div>

      <div v-if="mode!='add'">
        <dc-footer-bar>
          <dc-button size="small" @click="mode='add'">Добавить</dc-button>
        </dc-footer-bar>
      </div>


    </div>
  </div>


</template>

<script>
import {  IonItem,  IonLabel,  IonButton } from '@ionic/vue';
import { mapGetters, mapActions } from "vuex";
import DcInput from "./DcInput.vue";
import DcButton from "./DcButton.vue";
import DcFieldItem from "./DcFieldItem.vue";
import DcHeader from "@/components/DcHeader";
import DcFooterBar from "@/components/DcFooterBar";


export default {
    components: { IonItem,  IonLabel,  DcInput, DcFieldItem, DcButton, IonButton, DcHeader, DcFooterBar },
    props: ['items'],
    data() {
      return {
        error: '',
        mode: false,
        newNumber: '',
        newIsCTM: false,
        newComment: '',
      }
    },
    computed: {
      ...mapGetters("order", {
        invoices: "getCurrentOrderInvoices"
      })
    },
    methods: {
      ...mapActions("order", {
        invoiceAdd: "invoiceAdd",
        invoiceDel: "invoiceDel"
      }),
      /**
       * Сохранение квитанции
       */
      saveInvoice() {
        this.error = '';
        if (this.newNumber.length <= 0) {
          this.error = 'Вы не указали номер квитанции';
          return;
        } else {
          if (this.newNumber.length < 5) {
              this.error = 'Номер квитанции слишком короткий';
              return;
          }
        }

        if (this.invoices.find(item => item.number === this.newNumber)) {
          this.error = 'вы уже добавили квитанцию с таким номером';
          return;
        }

        this.invoiceAdd({
          number: this.newNumber,
          isCTM: this.newIsCTM,
          comment: this.newComment
        });

        this.newNumber = this.newComment = this.mode = '';
        this.newIsCTM = false;
      },
      /**
       * Удаление квитанции
       */
      removeInvoice(invoiceId) {
        this.invoiceDel({
          id: invoiceId
        });
      },
      setNumber(value) {
        if (typeof value === 'object') return;
        this.newNumber = value;
      },
      setComment(value) {
        if (typeof value === 'object') return;
        this.newComment = value;
      },
    }
}
</script>


<style>
.invoce-accept-add {
  margin: 16px 0;
}
</style>
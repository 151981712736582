<template>
  <base-layout pageTitle="Акции">
        <actions-list />
  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import ActionsList from "../components/ActionsList.vue";

export default {
    components: { BaseLayout, ActionsList },
    data() {
      return {}
    },
    computed: {}
}
</script>
<template>
  <base-layout pageTitle="Аб/орг">
    <orders-menu value="/abonent" />
    <order-list :key="lastUpdate>0 ? lastUpdate: 1" />
  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import OrderList from "../components/SpecialOrderList.vue";
import OrdersMenu from "../components/OrdersMenu.vue";

export default {
    components: { BaseLayout, OrderList, OrdersMenu, },
    data() {
      return {
        lastUpdate: 0
      }
    },
    async ionViewWillEnter() {
      this.lastUpdate++;
    }
}
</script>
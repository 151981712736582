<template>
  <base-layout :pageTitle="`Приемка. Заказ №`+order.id">
    <dc-header class="h1">Приемка. Заказ №{{order.id}}</dc-header>
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
    <ion-item color="success" v-if="success" class="result-message">
      <ion-label>{{success}}</ion-label>
    </ion-item>

    <div class="product-item" style="margin-top: 1rem;">
      <div class="product-item__body">
        <div class="product-item__title">Тип заказа</div>
      </div>
      <ion-select  interface="action-sheet" cancel-text="Отмена" v-model="order.departureType">
        <ion-select-option v-for="type in types" :value="type.id" :key="type.id">{{type.name}}</ion-select-option>
      </ion-select>
    </div>

    <div class="product-item" style="margin-top: 1rem;">
      <div class="product-item__body">
        <div class="product-item__title">Вид выезда</div>
      </div>
      <ion-select 
        interface="action-sheet" cancel-text="Отмена" 
        :value="order.isPremium ? '1':'0'" 
        @ionChange="setPremium($event.detail.value);">
        <ion-select-option value="0">Обычный</ion-select-option>
        <ion-select-option value="1">ВПТ</ion-select-option>
      </ion-select>
    </div>

    <order-accept-invoices v-if="ready && !isLoading" :items="order.invoices"></order-accept-invoices>
    <order-delivery-payment v-if="ready && !isLoading"></order-delivery-payment>

    <ion-item color="warning" v-if="!isLoading && order.slot.price && order.type=='acceptance'" class="result-message">
      <ion-label>Платный выезд {{order.slot.price}}</ion-label>
    </ion-item>

    <dc-footer-bar>
      <dc-button v-if="order.status=='inplace' && order.type=='acceptance'" @click="acceptOrder">
        Принять
      </dc-button>
    </dc-footer-bar>


  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import OrderAcceptInvoices from "../components/OrderAcceptInvoices.vue";
import OrderDeliveryPayment from "../components/OrderDeliveryPayment.vue";
import {  IonLabel, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { mapActions, mapGetters } from 'vuex'
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcHeader from "@/components/DcHeader";

export default {
    components: { BaseLayout,  IonLabel, IonItem, OrderAcceptInvoices, OrderDeliveryPayment, DcButton , DcFooterBar, DcHeader, IonSelect, IonSelectOption },
    mixins: [Loading],
    data() {
      return {
        error: '',
        success: '',
        orderId: false,
        ready: false,
      }
    },
    computed: {
      ...mapGetters("order", {
        order: "getCurrentOrder",
      }),
      ...mapGetters("registry", {
        types: "getDepartureTypes"
      })
    },
    methods: {
        ...mapActions('order', {
          acceptCurrentOrder: 'acceptCurrentOrder',
          fetchOrder: 'fetchOrder',
          setPremium: 'setPremiumCurrentOrder'
        }),
        /**
         * Заказ принят
         */        
        acceptOrder() {
          this.success = '';
          this.error = '';
          this.acceptCurrentOrder()
          .then(() => {
            this.success = "Заказ успешно принят";
            setTimeout(() => {
              this.$router.push({path: '/order/'+this.order.id});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        }
    },
    async mounted() {
      this.orderId = this.$route.params.orderId
      let order = await this.fetchOrder(this.orderId);
      order.payment = {};
      order.invoices = [];
      order.products = [];
      order.status = 'inplace';
      order.type = 'acceptance';
      this.$store.commit('order/setCurrentOrder', order);
      this.ready = true;
    }
}
</script>
import { createRouter, createWebHistory } from '@ionic/vue-router';
import MainPage from '../views/MainPage.vue';
import LoginPage from '../views/LoginPage.vue';
import OrderPage from '../views/OrderPage.vue';
import OrderCancelPage from '../views/OrderCancelPage.vue';
import OrderDeliveryPage from '../views/OrderDeliveryPage.vue';
import OrderAcceptPage from '../views/OrderAcceptPage.vue';
import OrderNewAcceptPage from '../views/OrderNewAcceptPage.vue';
import InternetErrorPage from '../views/InternetErrorPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import ReworkPage from '../views/ReworkPage.vue';
import StartShift from '../views/StartShift.vue';
import CloseShift from '../views/CloseShift.vue';
import MapPage from '../views/MapPage.vue';
import UIPage from '../views/UIPage.vue';
import ActionsPage from '../views/ActionsPage.vue';
import ActionDetailPage from '../views/ActionDetailPage.vue';
import CheckoutPage from '../views/CheckoutPage.vue';
import ReworkCheckoutPage from '../views/ReworkCheckoutPage.vue';
import AbonentPage from '../views/AbonentPage.vue';

const routes = [
  {
    path: '/',
    component: MainPage,
  },
  {
    path: '/abonent',
    component: AbonentPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/order/:orderId',
    component: OrderPage,
  },
  {
    path: '/order/cancel/:orderId',
    component: OrderCancelPage,
  },
  {
    path: '/order/delivery/:orderId',
    component: OrderDeliveryPage,
  },
  {
    path: '/order/accept/:orderId',
    component: OrderAcceptPage,
  },
  {
    path: '/order/newAccept/:orderId',
    component: OrderNewAcceptPage
  },
  {
    path: '/internetError',
    component: InternetErrorPage,
  },
  {
    path: '/startShift',
    component: StartShift,
  },
  {
    path: '/closeShift',
    component: CloseShift,
  },
  {
    path: '/map/:slotId/:done',
    component: MapPage,
  },
  {
    path: '/map/:slotId',
    component: MapPage,
  },
  {
    path: '/ui',
    component: UIPage,
  },
  {
    path: '/actions',
    component: ActionsPage,
  },
  {
    path: '/register/:orderId/:invoiceId',
    component: CheckoutPage,
  },
  {
    path: '/register',
    component: RegisterPage,
  },
  {
    path: '/rework/:orderId/:invoiceId',
    component: ReworkCheckoutPage,
  },
  {
    path: '/rework',
    component: ReworkPage,
  },
  {
    path: '/actions/:id',
    component: ActionDetailPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

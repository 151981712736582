/**
 * Парсим ошибку из ответа
 */
export const parseError = (response) => {
    if (response === undefined) {
        return {
            message: "CORS or network",
            code: 504,
        }
    }
    if (response.message && response.code) {
        return {
            message: response.message,
            code: response.code,
        }
    }

    const data = response.data
    const status = response.status || data.error.status
    let message = (data.error && data.error.message) || ""
    if (!message) {
        message = data.error || ""
    }

    if (!message) {
        switch (status) {
            case 500:
                message = "Ошибка сервера"
                break
            case 400:
                message = "Ошибка валидации"
                break
            case 401:
                message = "Ошибка авторизации"
                break
            default:
                message = "Неизвестная ошибка"
                break
        }
    }
    return {
        message: message,
        code: status,
    }
};

/**
 * Преобразуем дату
 * @param Date date 
 * @returns 
 */
 export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
};
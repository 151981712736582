<template>
  <base-layout :pageTitle="`Отмена. Заказ №`+order.id">
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
    <ion-item color="success" v-if="success" class="result-message">
      <ion-label>{{success}}</ion-label>
    </ion-item>

    <div class="order-detail-section" >
      <dc-header class="h1 " v-if="type=='cancel'">Отмена. Заказ №{{order.id}}</dc-header>
      <dc-header class="h1 " v-if="type=='move'">Перенос. Заказ №{{order.id}}</dc-header>
      <dc-footer-bar>
        <dc-button @click="type = 'cancel';" :active="type == 'cancel'">Отмена</dc-button>
        <dc-button @click="type = 'move';" :active="type == 'move'">Перенос</dc-button>
      </dc-footer-bar>

      <div class="product-item" style="margin-top: 1rem;">
        <div class="product-item__body">
          <div class="product-item__title">Причина</div>
        </div>
        <ion-select  interface="action-sheet" cancel-text="Отмена" v-model="reason">
          <ion-select-option v-for="(option, code) in availReasons" :value="code" :key="code">{{option}}</ion-select-option>
        </ion-select>
      </div>
      <dc-field-item label="Комментарий">
        <textarea  rows="3" v-model="comment" placeholder="Укажите комментарий" class="field-textarea"></textarea>
      </dc-field-item>

      <dc-button @click="cancelOrder" v-if="type=='cancel'">Отменить</dc-button>
      <dc-button @click="cancelOrder" v-if="type=='move'">Перенос</dc-button>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "../layout/BaseLayout.vue";
import OrderAction from "../mixins/OrderAction.js";
import Loading from "../mixins/Loading.js";
import {  IonItem,  IonSelect, IonSelectOption, IonLabel } from '@ionic/vue';
import { closeCircle, checkmark } from 'ionicons/icons';
import { mapActions } from 'vuex';
import DcButton from "@/components/DcButton";
import DcHeader from "@/components/DcHeader";
import DcFieldItem from "@/components/DcFieldItem";
import DcFooterBar from "@/components/DcFooterBar";



export default {
    components: { BaseLayout,  IonItem, IonSelect, IonSelectOption, DcButton, DcHeader, DcFieldItem, IonLabel, DcFooterBar },
    mixins: [Loading, OrderAction],
    data() {
      return {
        type: "cancel",
        comment: "",
        reason: "",
        error: "",
        success: "",
        closeCircle: closeCircle,
        checkmark: checkmark
      }
    },
    computed: {
      availReasons() {
        if (this.type=='move') { 
          return this.reasons.move;
        }
        return this.reasons.cancel;
      },
      reasons() {
        return this.$store.getters["registry/getReasons"];
      }
    },
    methods: {
      ...mapActions("order", {
        cancelCurrentOrder: "cancelCurrentOrder",
        moveCurrentOrder: "moveCurrentOrder",
      }),
      cancelOrder() {
        let comment = this.comment;
        this.error = this.success = "";
        if (!this.reason) {
          this.error = 'Вы не выбрали причину';
          return;
        }
        if (!comment) {
          this.error = 'Вы не указали комментарий';
          return;
        }
        if (this.type=='move') {
          this.moveCurrentOrder({
            reasonId: this.reason,
            comment: comment
          })
          .then(() => {
            this.success = "Заказ успешно перенесен";
            setTimeout(() => {
              this.$router.push({path: '/'});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        } else {
          this.cancelCurrentOrder({
            reasonId: this.reason,
            comment: comment
          })
          .then(() => {
            this.success = "Заказ успешно отменен";
            setTimeout(() => {
              this.$router.push({path: '/'});
            }, 2000)
          })
          .catch(error => {
            this.error = error.message;
          });
        }
      }
    }
}
</script>
<template>
    <input class="field-input"  :value="value" @input="$emit('input', $event.target.value); $emit('setval', $event.target.value)" :type="type ? type : 'text'" />
</template>

<script>
// const TYPES = {}
export default {
  name: "DcInput",
  props: ['value', 'type']
}
</script>

<style >


.field-input {
  margin: 0;
  padding: 0 var(--dc-indent-1);
  width: 100%;
  height: var(--dc-field-height);
  position: relative;
  line-height: 1;

  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  border: 1px solid var(--dc-color-input-border);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--dc-color-base);
  box-shadow: none;
  background: #fff;
  -webkit-appearance: none;
  appearance: none;
}
.field-input:focus {
  border-color: var(--dc-color-medium-gray);
  outline: none;
}



</style>

<template>

  <div class="order-card">
    <div class="order-card-head">
      <div class="order-card-head__title">
        <dc-header class="h1">
            Заказ  №{{order.id}}
        </dc-header>
      </div>
      <div class="order-card-head__status">
        <dc-badge  :type="order.status">{{getStatusLabel(order.status)}}</dc-badge >
      </div>
    </div>
    <div class="order-card-delivery">
      <div class="order-card-delivery-type">
        <div class="order-card-delivery-type__title">
          {{getTypeLabel(order.type)}}
        </div>
        <div class="order-card-delivery-type__date">
          на {{order.date}}
        </div>
      </div>
      <div class="order-card-delivery-time">
          <div v-if="order.slot" class="order-card-delivery-time__title">
            Желаемое время
          </div>
          <div v-if="order.slot" class="order-card-delivery-time__time">
            {{order.slot.time}}
          </div>
          <div v-if="order.isImportant" style="text-align: right;">
            <span class="badge badge_cancel">{{ order.importantComment }}</span>
          </div>
      </div>
    </div>
    <div  v-if="order.address">
      <div class="order-detail-section">
        <dc-header class="h3 is--gray">Адрес</dc-header>
        <div class="dc-detail-info">
          <div class="dc-detail-info_title">
            {{order.address.address}}
          </div>
          <div class="dc-detail-info_btn">
            <a :href="order['naviLink']"  class="dc-button dc-button_border">
              <ion-icon slot="icon-only" :icon="mapOutline" size="large"></ion-icon>
            </a>
          </div>
        </div>
        <div class="dc-feature-list">
          <div class="dc-feature-list-item" >
            <div class="dc-feature-list-item__title">Квартира:</div>
            <div class="dc-feature-list-item__val">{{order.address.apartment}}</div>
          </div>
          <div class="dc-feature-list-item" >
            <div class="dc-feature-list-item__title">Подъезд:</div>
            <div class="dc-feature-list-item__val">{{order.address.entrance}}</div>
          </div>
          <div class="dc-feature-list-item" >
            <div class="dc-feature-list-item__title">Этаж:</div>
            <div class="dc-feature-list-item__val">{{order.address.floor}}</div>
          </div>
          <div class="dc-feature-list-item" >
            <div class="dc-feature-list-item__title">Домофон:</div>
            <div class="dc-feature-list-item__val">{{order.address.intercom}}</div>
          </div>
        </div>
      </div>
      <div class="order-detail-section" v-if="order.address.comment">
        <dc-header class="h3 is--gray">Комментарий</dc-header>
        {{order.address.comment}}
      </div>
    </div>
    <div  v-if="order.client">
      <div class="order-detail-section">
        <dc-header class="h3 is--gray">Клиент</dc-header>
        <div class="dc-detail-info">
          <div class="dc-detail-info_title">
            {{order.client.name}}
            <dc-badge v-if="order.flags.isVip" color="cancel" >VIP</dc-badge>
            <dc-badge v-if="order.flags.isAbonent" color="cancel" >Аб/Орг</dc-badge>
          </div>
          <div class="dc-detail-info_btn">
            <a :href="'tel:'+getPhone(order.client.phone)"  class="dc-button dc-button_border"><ion-icon slot="icon-only" :icon="callOutline" size="large"></ion-icon></a>
<!--            <dc-button  shape="border"  @click="window.location= 'tel:{{order.client.phone}}'"><ion-icon slot="icon-only" :icon="callOutline" size="large"></ion-icon></dc-button>-->
          </div>
        </div>
        <div class="dc-feature-list">
          <div class="dc-feature-list-item" >
            <div class="dc-feature-list-item__title">Телефон:</div>
            <div class="dc-feature-list-item__val">{{getPhone(order.client.phone)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div  v-if="order.orderRecipient && order.orderRecipient.name && order.orderRecipient.phone">
      <div class="order-detail-section">
        <dc-header class="h3 is--gray">Получатель</dc-header>
        <div class="dc-detail-info">
          <div class="dc-detail-info_title">
            {{order.orderRecipient.name}}
          </div>
          <div class="dc-detail-info_btn">
            <a :href="'tel:'+getPhone(order.orderRecipient.phone)"  class="dc-button dc-button_border"><ion-icon slot="icon-only" :icon="callOutline" size="large"></ion-icon></a>
            <!--            <dc-button  shape="border"  @click="window.location= 'tel:{{order.client.phone}}'"><ion-icon slot="icon-only" :icon="callOutline" size="large"></ion-icon></dc-button>-->
          </div>
        </div>
        <div class="dc-feature-list">
          <div class="dc-feature-list-item" >
            <div class="dc-feature-list-item__title">Телефон:</div>
            <div class="dc-feature-list-item__val">{{getPhone(order.orderRecipient.phone)}}</div>
          </div>
        </div>
      </div>
    </div>


  </div>


</template>

<script>
import { 
  IonIcon,
  } from '@ionic/vue';
import { callOutline,mapOutline, warning, wifi, wine, closeCircle } from 'ionicons/icons';
import { defineComponent } from 'vue';
import OrderHelper from "../mixins/OrderHelper.js";
import DcBadge from "../components/Badge.vue";
import DcHeader from "../components/DcHeader.vue";
import { mapGetters } from 'vuex';
// import DcButton from "@/components/DcButton";

export default defineComponent({
    components: {

      IonIcon,
      DcBadge,
      DcHeader
    },
    mixins: [OrderHelper],
    data() {
      return {
        mapOutline: mapOutline,
        callOutline: callOutline,
        warning: warning,
        wifi: wifi,
        wine: wine,
        closeCircle: closeCircle
      }
    },
    computed: {
      ...mapGetters("order", {
        order: "getCurrentOrder"
      })
    },
    methods: {
      getPhone(phone) {
        if (phone.charAt(0)=='7') {
          return '+' + phone;
        }
        if (phone.charAt(0)=='9') {
          return '+7' + phone;
        }
        return phone;
      }
    }
});
</script>

<style >

  .order-card {
    margin-bottom: var(--dc-indent-3);
  }

  .order-card-head {
    margin-bottom: var(--dc-indent-3);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-card-head__title {

  }
  .order-card-head__title .h1 {
    margin-bottom: 0;
  }
  .order-card-head__status {}
  .order-card-delivery {
    margin-bottom: var(--dc-indent-3);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-card-delivery-type {

    line-height: 1.3;
  }
  .order-card-delivery-type__title {

    font-size: 18px;
    font-weight: 500;
  }
  .order-card-delivery-type__date  {
    font-size: 14px;
    color: var(--dc-color-medium-gray);
  }
  .order-card-delivery-time  {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--dc-color-brand);
  }
  .order-card-delivery-time__title  {

  }
  .order-card-delivery-time__time  {
    font-weight: bold;
    font-size: 18px;
  }
  .order-detail-section {
    margin-bottom: var(--dc-indent-3);
  }
  .order-detail-section .field {
    margin-bottom: var(--dc-indent-1);
  }

  .dc-detail-info {
    margin-bottom: var(--dc-indent-2);
    display: flex;
    align-items: center;
  }
  .dc-detail-info_title {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
  }
  .dc-detail-info_btn {
    margin-left: var(--dc-indent-2);
    flex: 1 0 60px;
  }

  .dc-detail-info_btn .dc-button_border {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<template>
<div>
  <dc-header class="h2">Скидки</dc-header>
  <div class="product-list">
    <div  v-if="discounts && discounts['applied'] && discounts.applied.length > 0">
    <div class="product-list__item" v-for="item in discounts.applied" v-bind:key="item.id">
      <label class="checked-button">
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">{{item.name}}</div>
            <div class="product-item__note">{{item.percent}}%</div>
          </div>
          <ion-button @click="delDiscount(item.id)" color="danger">X</ion-button>
        </div>
      </label>
    </div>
    </div>
    <div v-else class="product-list__item">
      <label class="checked-button">
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">Не применено ни одной скидки</div>
          </div>
        </div>
      </label>
    </div>

    <div class="invoce-accept-add"  v-if="mode=='add' && discounts && discounts['avail'] && discounts.avail.length > 0">
        <dc-header class="h2 ">Добавить скидку</dc-header>
        <ion-item color="danger" v-if="error">
          <ion-label>{{error}}</ion-label>
        </ion-item>
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">Скидка</div>
          </div>
          <ion-select v-model="discountId"  interface="action-sheet" cancel-text="Отмена" cancelText="Отмена" >
            <ion-select-option v-for="discount in discounts.avail" :value="discount.id" :key="discount.id">{{discount.name}}</ion-select-option>
          </ion-select>
        </div>
        <dc-field-item label="Номер документа">
          <input class="field-input" v-model="docNumber" />
        </dc-field-item>
        <dc-footer-bar>
          <dc-button size="small" @click="mode=''; error='';" shape="border">Отмена</dc-button>
          <dc-button size="small" @click="addDiscount">Добавить</dc-button>
        </dc-footer-bar>
    </div>

    <div v-if="mode!='add' && discounts && discounts['avail'] && discounts.avail.length > 0">
      <dc-footer-bar>
        <dc-button size="small" @click="mode='add'">Добавить скидку</dc-button>
      </dc-footer-bar>
    </div>


  </div>
  <dc-footer-bar v-if="mode!='add'">
    <dc-button @click="$emit('back')" shape="border">Назад</dc-button>
    <dc-button @click="$emit('done')" >Продолжить</dc-button>
  </dc-footer-bar>
</div>
</template>

<script>
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcHeader from "@/components/DcHeader";
import DcFieldItem from "../DcFieldItem.vue";
import Loading from "../../mixins/Loading.js";
import { getDiscountsService, addDiscountService, delDiscountService } from "../../api/discounts.js";
import {
  IonButton,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption
} from "@ionic/vue";
import { calcInvoiceService, saveInvoiceService } from '../../api/invoice';

export default {
    components: {DcButton, DcFooterBar, DcHeader, 
                DcFieldItem, IonButton, IonLabel, IonItem, 
                IonSelect, IonSelectOption},
    mixins: [Loading],
    props: ['invoice', 'orderId'],
    data() {
      return {
        invoiceItem: this.invoice,
        error: '',
        discountId: '',
        mode: 'view',
        docNumber: '',
        discounts: [],
      }
    },
    computed: {
      order () {
        let orders = this.$store.getters["order/getRegisterOrders"];
        for(let x in orders) {
          if (orders[x].id == this.orderId) {
            return orders[x];
          }
        }
        return false;
      },
    },
    watch: {
      invoice: function(val) {
        this.invoiceItem = val;
      }
    },
    methods: {
      /**
       * добавляем скидку
       */
      async addDiscount() {
        this.error = '';
        this.$store.dispatch("order/setIsLoading", true);
        for (let x in this.discounts.avail) {
          if (this.discounts.avail[x].id==this.discountId) {
            if (this.discounts.avail[x].doc_id * 1 && !this.docNumber) {
              this.error = 'Вы не указали номер документа';
              return;
            }
          }
        }
        try {
          let discounts = await addDiscountService(this.order, this.invoiceItem, this.discountId, this.docNumber);
          this.discounts = discounts;
          this.setInvoiceDiscount();
          this.mode = 'view';
          this.docNumber = '';
          await this.recalcOtherInvoices();
        } catch(e) {
          console.log(e);
        }
        this.$store.dispatch("order/setIsLoading", false);
      },
      /**
       * удаляем скидку
       */
      async delDiscount(discountId) {
        this.error = '';
        try {
          let discounts = await delDiscountService(this.orderId, this.invoiceItem, discountId);
          this.discounts = discounts;
          this.setInvoiceDiscount();
          this.mode = 'view';
          this.docNumber = '';
        } catch(e) {
          this.error = e.message;
        }
      },
      /**
       * Записываем скидки в хранилище
       */
      setInvoiceDiscount() {
        let data = {
          invoiceId: this.invoiceItem.id,
          orderId: this.orderId,
          fields: {
            discounts: this.discounts.applied,
            items: this.invoiceItem.items,
          }
        }
        this.$store.dispatch('order/setInvoice', data);
      },
      /**
       * получем список доступных скидок
       */
      async getDiscounts() {
        this.$store.dispatch("order/setIsLoading", true);
        try {
          if (this.invoiceItem.discounts.length == 0) {
            this.invoiceItem.discounts = this.getOrderDiscounts();
          }
          let discounts = await getDiscountsService(this.order, this.invoiceItem);
          this.discounts = discounts;
          this.setInvoiceDiscount();
        } catch(e) {
          this.error = e.message;
        }
        this.$store.dispatch("order/setIsLoading", false);
      },
      /**
       * пересчитываем другие квитанции в заказе
       */
      async recalcOtherInvoices() {
        if (this.order.invoices.length == 1) {
          return ;
        }

        /**
         * Собираем все скидки в заказе
         */
        let orderDiscounts = this.getOrderDiscounts();

        /**
         * Бежим по всем квитанцяим кроме текущей и применяем скидки, которых не было
         */
        for(let x in this.order.invoices) {
          if (this.order.invoices[x].id == this.invoiceItem.id) {
            continue;
          }
          if (!this.order.invoices[x].items || this.order.invoices[x].items.length == 0) {
            continue;
          }
          let invoice = this.order.invoices[x];
          const notApplied = orderDiscounts.filter(item => invoice.discounts.map(item => item.id).indexOf(item.id) < 0);
          for(let y in notApplied) {
            let invoiceDiscounts = await addDiscountService(this.order, invoice, notApplied[y].id, notApplied[y].docNumber);
            invoice.discounts = invoiceDiscounts.applied;
          }
          const invoiceTotal = await calcInvoiceService(this.order, invoice);
          invoice.total = invoiceTotal.total;
          for(let x in invoice.items) {
            const itemTotal = invoiceTotal.items.find(item => item.id == invoice.items[x].id);
            if (itemTotal) {
              invoice.items[x] = {...invoice.items[x], ...itemTotal};
            }
          }
          this.$store.dispatch('order/setInvoice', {
            invoiceId: invoice.id,
            orderId: this.orderId,
            fields: {
              discounts: invoice.discounts,
              payment: {
                summ: invoice.total.total
              },
              items: invoice.items,
              total: invoice.total,
            }
          });
          await saveInvoiceService(this.order, invoice);
        }
      },
      
      getOrderDiscounts() {
        let orderDiscounts = [];
        for(let x in this.order.invoices) {
          const invoice = this.order.invoices[x];
          if (invoice.discounts && invoice.discounts.length > 0) {
            orderDiscounts = [...orderDiscounts, ...invoice.discounts.filter(
              item => orderDiscounts.map(
                item => item.id
              ).indexOf(item.id)<0)];
          }
        }
        return orderDiscounts;
      },
    },
    async mounted() {

      await this.getDiscounts();
    },
}
</script>


<style  >

.order-list-item {
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
  .order-list-item.is--disabled {
    color: var(--dc-color-medium-gray);
  }
  .order-list-item.is--disabled  .order-list-item__time {
    color: var(--dc-color-medium-gray);
  }

.order-list-item__aside {
    margin-right: 16px;
    flex: 0 0 80px;
  }
  .order-list-item-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .order-list-item-type__ico  {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .order-list-item-type__title  {
    font-weight: 500;
  }
  .order-list-item__time {
    color: var(--dc-color-brand);
  }


  .order-list-item__main {
    flex: 0 1 100%;
  }
  .order-list-item__top  {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-item__id {
    font-size: 14px;
    font-weight: 500;
  }
  .order-list-item__status {}
  .order-list-item__title  {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .order-list-item__client {}
  .order-list-item.selected {
    border-color:var(--dc-color-brand);
  }
</style>

<template>
<div>
  <dc-header class="h2">Наименование услуг</dc-header>
  <div class="product-list">
    <div class="product-list__item" v-for="item in invoiceItem.items" v-bind:key="item.id">
      <label class="checked-button">
        <div class="product-item">
          <div class="product-item__body">
            <div class="product-item__title">{{item.name}}</div>
            <div class="product-item__note">{{item.price}} руб. X {{item.quantity}}</div>
            <div class="product-item__note">{{item.description.color}}</div>
          </div>
          <ion-button @click="removeItem(item.id)" color="danger">X</ion-button>
        </div>
      </label>
    </div>

    <div class="invoce-accept-add"  v-if="mode=='add'">
        <ion-item color="danger" v-if="error">
          <ion-label>{{error}}</ion-label>
        </ion-item>
        <dc-field-item label="Услуга">
          <dc-autocomplete :list="services" v-model="serviceId"></dc-autocomplete>
        </dc-field-item>
        <dc-field-item label="Количество">
          <dc-input :value="quantity" @setval="setQuantity" type="number"></dc-input>
        </dc-field-item>
        <dc-field-item label="Цвет">
          <input class="field-input" v-model="color" />
        </dc-field-item>
        <dc-footer-bar>
          <dc-button size="small" @click="mode=''; error='';" shape="border">Отмена</dc-button>
          <dc-button v-if="!inSave" size="small" @click="saveItem">Добавить</dc-button>
        </dc-footer-bar>

    </div>

    <div v-if="mode!='add'">
      <dc-footer-bar>
        <dc-button size="small" @click="mode='add'">Добавить услугу</dc-button>
      </dc-footer-bar>
    </div>


  </div>
  <dc-footer-bar v-if="mode!='add'">
    <dc-button @click="$emit('back')" shape="border">Назад</dc-button>
    <dc-button v-if="invoice.items && invoice.items.length > 0" @click="confirm" >Продолжить</dc-button>
    <dc-button v-else :active="false">Продолжить</dc-button>
  </dc-footer-bar>
</div>
</template>

<script>
import DcButton from "@/components/DcButton";
import DcFooterBar from "@/components/DcFooterBar";
import DcHeader from "@/components/DcHeader";
import DcAutocomplete from "../../components/DcAutocomplete.vue";
import DcFieldItem from "../../components/DcFieldItem.vue";
import DcInput from "../../components/DcInput.vue";
import { getServicesService } from "../../api/order.js";
import {
  IonButton,
  IonLabel,
  IonItem
} from "@ionic/vue";

export default {
    components: {DcButton, DcFooterBar, DcHeader, DcFieldItem, DcInput, IonButton, IonLabel, IonItem, DcAutocomplete},
    props: ['invoice', 'orderId'],
    data() {
      return {
        invoiceItem: this.invoice,
        error: '',
        mode: 'view',
        serviceId: false,
        quantity: 1,
        color: '',
        colors: [
          { id: 'Черный', name: 'Черный'},
          { id: 'Синий', name: 'Синий'},
          { id: 'Серый', name: 'Серый'},
          { id: 'Бежевый', name: 'Бежевый'},
          { id: 'Белый', name: 'Белый'},
          { id: 'Бордовый', name: 'Бордовый'},
          { id: 'Бронзовый', name: 'Бронзовый'},
          { id: 'Голубой', name: 'Голубой'},
          { id: 'Желтый', name: 'Желтый'},
          { id: 'Зеленый', name: 'Зеленый'},
          { id: 'Золотой', name: 'Золотой'},
          { id: 'Коричневый', name: 'Коричневый'},
          { id: 'Красный', name: 'Красный'},
          { id: 'Оранжевый', name: 'Оранжевый'},
          { id: 'Персиковый', name: 'Персиковый'},
          { id: 'Розовый', name: 'Розовый'},
          { id: 'Салатовый', name: 'Салатовый'},
          { id: 'Серебряный', name: 'Серебряный'},
          { id: 'Сиреневый', name: 'Сиреневый'},
          { id: 'Фиолетовый', name: 'Фиолетовый'}
        ],
        services: [],
        state: false,
        inSave: false,
      }
    },
    watch: {
      invoice: function(val) {
        this.invoiceItem = val;
      }
    },
    computed: {
      types: function() {
        return this.$store.getters['registry/getServiceTypes'];
      }
    },
    methods: {
      formatDate (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');
      },
      setQuantity(val) {
        this.quantity = val;
      },
      saveItem() {
        this.inSave = true;
        let id = 0;
        if (!this.invoiceItem.items) {
          this.invoiceItem.items = [];
        }
        if (this.invoiceItem.items && this.invoiceItem.items.length>0) {
          for(let x in this.invoiceItem.items) {
            if (this.invoiceItem.items[x].id > id) {
              id = this.invoiceItem.items[x].id;
            }
          }
        }
        id++;
        let service = false;
        for (let x in this.services) {
          if (this.serviceId == this.services[x].id) {
            service = this.services[x];
          }
        }
        if (!service) {
          this.error = 'Вы не выбрали услугу';
          this.inSave = false;
          return;
        }
        if (this.quantity<=0) {
          this.error = 'Вы не указали количество';
          this.inSave = false;
          return;
        }
        if (!this.color) {
          this.error = 'Вы не указали цвет';
          this.inSave = false;
          return;
        }
        this.invoiceItem.items.push({
          name: service.name,
          price: service.price,
          noDiscount: service.noDiscount,
          serviceId: this.serviceId,
          quantity: this.quantity,
          description: {
            color: this.color
          },
          id: id
        });
        this.mode = '';
        this.serviceId = '';
        this.quantity = '1';
        this.color = '';
        this.error = '';
        this.setInvoiceItems();
        this.inSave = false;
      },
      setInvoiceItems() {
        let data = {
          invoiceId: this.invoiceItem.id,
          orderId: this.orderId,
          fields: {
            items: this.invoiceItem.items
          }
        }
        this.$store.dispatch('order/setInvoice', data);
      },
      removeItem(id) {
        for(let x in this.invoiceItem.items) {
          if (this.invoiceItem.items[x].id == id) {
            this.invoiceItem.items.splice(x, 1);
            break;
          }
        }
        this.setInvoiceItems();
      },
      async getServices() {
        let services = await getServicesService(this.orderId, this.invoiceItem.type, this.invoiceItem.isPremium);
        this.services = services;
      },
      async confirm() {
        this.$emit('done');
      },
    },
    mounted() {
      this.getServices();
      if (!this.invoiceItem.items || this.invoiceItem.items.length == 0)  {
        this.mode='add';
      }
    },
}
</script>


<style  >

.order-list-item {
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
  .order-list-item.is--disabled {
    color: var(--dc-color-medium-gray);
  }
  .order-list-item.is--disabled  .order-list-item__time {
    color: var(--dc-color-medium-gray);
  }

.order-list-item__aside {
    margin-right: 16px;
    flex: 0 0 80px;
  }
  .order-list-item-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .order-list-item-type__ico  {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .order-list-item-type__title  {
    font-weight: 500;
  }
  .order-list-item__time {
    color: var(--dc-color-brand);
  }


  .order-list-item__main {
    flex: 0 1 100%;
  }
  .order-list-item__top  {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-item__id {
    font-size: 14px;
    font-weight: 500;
  }
  .order-list-item__status {}
  .order-list-item__title  {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .order-list-item__client {}
  .order-list-item.selected {
    border-color:var(--dc-color-brand);
  }
</style>
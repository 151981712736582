<template>
  <base-layout pageTitle="Реестр доработки" >
    <dc-header class="h1">Квитанция №{{invoice.number}}</dc-header>
    <invoice-types :invoice="invoice" :order-id="orderId" v-if="step=='type'" @done="step = 'items'" @back="$router.push({path: '/rework'});"></invoice-types>
    <invoice-items :invoice="invoice" :order-id="orderId" v-if="step=='items'" @done="step = 'number'" @back="step = 'type'"></invoice-items>
    <invoice-number :invoice="invoice" :order-id="orderId" v-if="step=='number'" @done="step=='type'; $router.push({path: '/rework'});" @back="step = 'items'"></invoice-number>
    <ion-item color="danger" v-if="error" class="result-message">
      <ion-label>{{error}}</ion-label>
    </ion-item>
  </base-layout>
</template>

<script>

import BaseLayout from "../layout/BaseLayout.vue";
import Loading from "../mixins/Loading.js";
import DcHeader from "@/components/DcHeader";
import { IonItem, IonLabel } from "@ionic/vue";
import OrderHelper from "../mixins/OrderHelper.js";
import InvoiceTypes from "../components/Checkout/InvoiceTypes.vue";
import InvoiceItems from "../components/Checkout/InvoiceItems.vue";
import InvoiceNumber from "../components/Checkout/InvoiceNumber.vue";

export default  {
  components: {BaseLayout, DcHeader, IonLabel, IonItem, InvoiceTypes, InvoiceItems, InvoiceNumber},
  mixins: [Loading, OrderHelper],
  data() {
    return {
      error: '',
      orderId: '',
      invoiceId: '',
      step: 'type'
    }
  },
  computed: {
    order () {
      let orders = this.$store.getters["order/getReworkOrders"];
      for(let x in orders) {
        if (orders[x].id == this.orderId) {
          return orders[x];
        }
      }
      return false;
    },
    invoice () {
      if (this.order) {
        for(let x in this.order.invoices) {
          if (this.order.invoices[x].id == this.invoiceId) {
            return this.order.invoices[x];
          }
        }
      }
      return false;
    }
  },
  methods: {},
  mounted() {
    this.invoiceId = this.$route.params['invoiceId'];
    this.orderId = this.$route.params['orderId'];
  }
}
</script>

<style >

.is--disabled {
  color: var(--dc-color-medium-gray);
}
.product-item__status {
    right: 50px;
    position: absolute;
}
</style>

<template>
  <div>
  <div class="order-list-item" v-bind:class="(!order.active)? 'is--disabled' : ''">
    <div class="order-list-item__aside">
      <div class="order-list-item-type">
        <div class="order-list-item-type__ico">
          <div v-if="order.type=='delivery'">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36"><path fill="#008C9A" d="M35.5 20a1.5 1.5 0 0 0-3 0h3ZM34 30h1.5H34Zm-4 4v-1.5V34ZM6 34v1.5V34Zm-4-4h1.5H2Zm1.5-10a1.5 1.5 0 0 0-3 0h3Zm21.44-8.94a1.5 1.5 0 0 0 2.12-2.12l-2.12 2.12ZM18 2 19.06.94a1.5 1.5 0 0 0-2.12 0L18 2ZM8.94 8.94a1.5 1.5 0 0 0 2.12 2.12L8.94 8.94ZM16.5 26a1.5 1.5 0 0 0 3 0h-3Zm16-6v10h3V20h-3ZM30 32.5H6v3h24v-3ZM3.5 30V20h-3v10h3ZM6 32.5A2.5 2.5 0 0 1 3.5 30h-3A5.5 5.5 0 0 0 6 35.5v-3ZM32.5 30a2.5 2.5 0 0 1-2.5 2.5v3a5.5 5.5 0 0 0 5.5-5.5h-3ZM27.06 8.94l-8-8-2.12 2.12 8 8 2.12-2.12Zm-10.12-8-8 8 2.12 2.12 8-8L16.94.94ZM16.5 2v24h3V2h-3Z"/></svg>
          </div>
          <div v-if="order.type=='acceptance'">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 37"><path stroke="#008C9A" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M34 25.5v6a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-6m8-8 8 8m0 0 8-8m-8 8v-24"/></svg>
          </div>
        </div>
        <div class="order-list-item-type__title">
          {{getTypeLabel(order.type)}}
        </div>
        <div class="order-list-item__time">
          {{order.slot.time}}
        </div>
        <div v-if="order.status=='new'">
          <span class="badge badge_cancel">Требуется подтверждение</span>
        </div>
        <div v-if="order.isImportant">
          <span class="badge badge_cancel">{{ order.importantComment }}</span>
        </div>
        <div v-if="order.slot.price && order.type=='acceptance'">
          <span class="badge badge_complete">Платный выезд {{order.slot.price}}</span>
        </div>
      </div>
    </div>
    <div class="order-list-item__main">
      <div class="order-list-item__top">
        <div class="order-list-item__id">
          № {{order.id}} {{getOrderInvoices(order)}}
        </div>
        <div class="order-list-item__status">
          <dc-badge :color="order.status" >{{getStatusLabel(order.status)}}</dc-badge>
        </div>
      </div>
      <div class="order-list-item__title">
        {{order.address.address}}
      </div>
      <div class="order-list-item__client">
        {{order.client.name}}  {{order.client.phone}}
        <dc-badge v-if="order.flags.isVip" color="cancel" >VIP</dc-badge>
        <dc-badge v-if="order.flags.isAbonent" color="cancel" >Аб/Орг</dc-badge>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import OrderHelper from "../mixins/OrderHelper.js";
import DcBadge from "../components/Badge.vue";

export default {
    components: {DcBadge},
    props: ['orderItem'],
    mixins: [OrderHelper],
    data() {
      return {
        order: this.orderItem
      }
    },
    methods: {
      getOrderInvoices(order) {
        if (order.invoices && order.invoices.length > 0) {
          return "(" + order.invoices.map(item => item.number).join(', ') + ")";
        }
        return '';
      }
    },
    mounted() {
      // this.order = this.orderItem;
    },
    watch: {
      orderItem: {
        handler(val) {
          this.order = val;
        }
      }
    },
}
</script>


<style  >

.order-list-item {
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
  .order-list-item.is--disabled {
    color: var(--dc-color-medium-gray);
  }
  .order-list-item.is--disabled  .order-list-item__time {
    color: var(--dc-color-medium-gray);
  }

.order-list-item__aside {
    margin-right: 16px;
    flex: 0 0 80px;
  }
  .order-list-item-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .order-list-item-type__ico  {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .order-list-item-type__title  {
    font-weight: 500;
  }
  .order-list-item__time {
    color: var(--dc-color-brand);
  }


  .order-list-item__main {
    flex: 0 1 100%;
  }
  .order-list-item__top  {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-item__id {
    font-size: 14px;
    font-weight: 500;
  }
  .order-list-item__status {}
  .order-list-item__title  {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .order-list-item__client {}
</style>

<template>
    <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div>
      <dc-splash-screen v-if="getShiftStatus === 'empty'">
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 86">
            <path fill="currentColor" fill-rule="evenodd" d="M4.02 81.76h66.96V32.43H4.02v49.33Zm68.72 3.53H2.26C1.3 85.29.5 84.5.5 83.53V30.67c0-.97.79-1.76 1.76-1.76h70.48c.97 0 1.76.79 1.76 1.76v52.86c0 .97-.79 1.76-1.76 1.76Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M14.6 81.76h45.8V43H14.6v38.76Zm47.57 3.53H12.83c-.97 0-1.76-.79-1.76-1.76V41.24c0-.97.79-1.76 1.76-1.76h49.34c.97 0 1.76.79 1.76 1.76v42.29c0 .97-.79 1.76-1.76 1.76Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M14.6 81.76h45.8v-7.04H14.6v7.04Zm47.57 3.53H12.83c-.97 0-1.76-.79-1.76-1.76V72.95c0-.97.79-1.76 1.76-1.76h49.34c.97 0 1.76.8 1.76 1.76v10.58c0 .97-.79 1.76-1.76 1.76Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M14.6 71.2h45.8v-7.05H14.6v7.04Zm47.57 3.52H12.83c-.97 0-1.76-.8-1.76-1.77V62.38c0-.97.79-1.76 1.76-1.76h49.34c.97 0 1.76.8 1.76 1.76v10.57c0 .98-.79 1.77-1.76 1.77Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M14.6 60.62h45.8v-7.05H14.6v7.05Zm47.57 3.53H12.83c-.97 0-1.76-.8-1.76-1.77V51.81c0-.97.79-1.76 1.76-1.76h49.34c.97 0 1.76.79 1.76 1.76v10.57c0 .98-.79 1.77-1.76 1.77Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M14.6 50.05h45.8V43H14.6v7.05Zm47.57 3.52H12.83c-.97 0-1.76-.78-1.76-1.76V41.24c0-.97.79-1.76 1.76-1.76h49.34c.97 0 1.76.79 1.76 1.76v10.57c0 .98-.79 1.76-1.76 1.76ZM4.02 28.9h66.96v-3.75C67.9 18.6 58.8 4.24 37.95 4.24H37.05C16.2 4.24 7.1 18.59 4.02 25.16v3.75Zm33.48 3.53H2.26c-.97 0-1.76-.79-1.76-1.76v-5.9c0-.26.05-.5.16-.73 3-6.6 7.5-12.2 13.03-16.17C20.3 3.12 28.15.72 37.05.72H37.95c8.9 0 16.76 2.4 23.36 7.14a40.44 40.44 0 0 1 13.03 16.18c.1.22.16.47.16.73v5.9c0 .97-.79 1.76-1.76 1.76H37.5Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M37.5 11.29a5.3 5.3 0 1 0 .01 10.58 5.3 5.3 0 0 0 0-10.58Zm0 14.1a8.82 8.82 0 0 1 0-17.62 8.82 8.82 0 0 1 0 17.61Z" clip-rule="evenodd"/>
          </svg>
        </template>
        <template v-slot:text>
          Для доступа к маршрутному листу нужно начать смену
        </template>
        <template v-slot:button>
          <dc-button @click="shiftOpen">Начать смену</dc-button>
        </template>
      </dc-splash-screen>

      <dc-splash-screen v-if="isNoOrders">
        <template v-slot:icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87 78">
            <path fill="currentColor" fill-rule="evenodd" d="M4.6 20.4h51.43l-5.8-15.96H11.85L4.61 20.4Zm53.97 3.54H1.84a1.77 1.77 0 0 1-1.61-2.51L9.1 1.93C9.39 1.3 10.02.9 10.71.9h40.77c.74 0 1.4.46 1.66 1.16l7.05 19.4a1.77 1.77 0 0 1-1.62 2.48Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M30.2 23.94c-.97 0-1.76-.8-1.76-1.78V2.67a1.77 1.77 0 1 1 3.54 0v19.5c0 .97-.8 1.77-1.77 1.77Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M3.63 73.56h45.7a24.81 24.81 0 0 1 7.47-45.5v-4.12H3.62v49.62Zm54.93 3.54H1.86c-.99 0-1.78-.8-1.78-1.77V22.16c0-.98.8-1.77 1.77-1.77h56.71c.98 0 1.78.8 1.78 1.77v7.36c0 .88-.64 1.62-1.5 1.75a21.4 21.4 0 0 0-18 21.02 21.4 21.4 0 0 0 18 21.02c.86.13 1.5.88 1.5 1.75v.27c0 .98-.8 1.77-1.78 1.77Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M62.1 31.02A21.3 21.3 0 0 0 40.85 52.3a21.3 21.3 0 0 0 21.27 21.27 21.29 21.29 0 0 0 21.26-21.27 21.29 21.29 0 0 0-21.26-21.27Zm0 46.08a24.84 24.84 0 0 1-24.8-24.8 24.84 24.84 0 0 1 24.8-24.82 24.84 24.84 0 0 1 24.82 24.81A24.84 24.84 0 0 1 62.1 77.1Z" clip-rule="evenodd"/>
            <path fill="currentColor" fill-rule="evenodd" d="M70.97 54.06H62.1c-.98 0-1.77-.79-1.77-1.77V38.11a1.77 1.77 0 1 1 3.54 0v12.4h7.09a1.77 1.77 0 0 1 0 3.55Z" clip-rule="evenodd"/>
          </svg>
        </template>
        <template v-slot:text>
          Заказов на сегодня не найдено
        </template>
      </dc-splash-screen>


        <div v-for="slot in getSortedOrders" :key="slot.key" :class="{'is--disabled': !slot.active, 'is--new': slot.isNew}" class="slot-list-item">
          <div class="wrap-slot-list-item-title">
            <div class="slot-list-item__title">

              {{slot.title}}

            </div>
            <span>
              <a href="javascript:void(0);" @click="$router.push(`/map/`+slot.key)" class="is--link">На карту</a>
            </span>
          </div>
          <div class="slot-list-item__body">
            <div v-for="order in slot.orders" :key="order.id" :class="{'is--disabled': !order.active, 'is--new': slot.isNew}" @click="$router.push(`/order/`+order.id)">
              <order-list-item :orderItem="order"></order-list-item>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import {
   IonRefresher, IonRefresherContent
} from "@ionic/vue";

import OrderHelper from "../mixins/OrderHelper.js";
import OrderListItem from "../components/OrderListItem.vue";
import Loading from "../mixins/Loading.js";
import { mapGetters, mapActions } from 'vuex'
import DcSplashScreen from "@/components/DcSplashScreen";
import DcButton from "@/components/DcButton";
import {$api} from "@/plugins/Axios";
import endpoints from "@/api/endpoints";

export default {
    components: { OrderListItem, IonRefresher, IonRefresherContent, DcSplashScreen, DcButton},
    mixins: [OrderHelper, Loading],
    computed: {
        ...mapGetters("order", ["getSortedOrders", "getShiftStatus"]),
      isNoOrders() {
          return (!this.getSortedOrders || this.getSortedOrders.length==0) && this.getShiftStatus !== 'empty';
      }
    },
    methods: {
        ...mapActions('order', {
            fetchOrders: 'fetchOrders',
            shiftClose: 'shiftClose'
        }),
        doRefresh(event) {
          this.fetchData();
          setTimeout(() => {
                    event.target.complete();
          }, 2000);
        },
        async fetchData() {
          let response = await $api.get(endpoints.orders.timestamp);
          console.log(response.data.result);
          if(response.data.result) {
            this.fetchOrders(new Date(response.data.result * 1000)).catch(error => {
              if(error.code == 401) {
                this.$router.replace({path: '/login'});
              } else {
                setTimeout(() => {
                  this.$router.push({path: '/internetError'});
                }, 500);
              }
            })
          }
        },
        shiftOpen() {
          this.$router.push({path: '/startShift'});
        }
    },
    mounted() {
      this.fetchData();
      this.checkLoading();
    }
}
</script>


<style >
.slot-list-item {
  margin-bottom: var(--dc-indent-3);
}
.wrap-slot-list-item-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.slot-list-item__title {
  font-size: 16px;
  font-weight: bold;
  color: var(--dc-color-brand)
}
.is--disabled .slot-list-item__title {
  color: var(--dc-color-medium-gray);
}
.slot-list-item__body {}
.is--new .order-list-item {
  background-color: #ffeeee;
  border-color: var(--brand-color-red);
}
</style>
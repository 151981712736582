<template>
  <ion-app>
    <ion-menu side="start" content-id="main-content">
      <ion-header>
        <ion-toolbar translucent>
          <ion-title>Приложение для водителей</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item @click="orders">
            <ion-icon src="/assets/images/car.svg" slot="start"></ion-icon>
            <ion-label>Заказы</ion-label>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item @click="abonent">
            <ion-icon src="/assets/images/attention.svg" slot="start"></ion-icon>
            <ion-label>Аб/Орг</ion-label>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item @click="register">
            <ion-icon src="/assets/images/doc.svg" slot="start"></ion-icon>
            <ion-label>Реестр приемки</ion-label>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item @click="rework">
            <ion-icon src="/assets/images/doc.svg" slot="start"></ion-icon>
            <ion-label>Реестр доработки</ion-label>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item @click="actions">
            <ion-icon src="/assets/images/star.svg" slot="start"></ion-icon>
            <ion-label>Акции</ion-label>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item @click="update">
            <ion-icon src="/assets/images/sync.svg" slot="start"></ion-icon>
            <ion-label>
              Обновление
              <p style="color: var(--brand-color-delete) !important;" v-if="newVersionAvailable"> доступна новая
                версия!</p>
            </ion-label>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item @click="logout">
            <ion-icon src="/assets/images/logout.svg" slot="start"></ion-icon>
            <ion-label>Выход</ion-label>
          </ion-item>
        </ion-list>
        <ion-note color="medium" style="float: right;">Версия {{version}}</ion-note>
      </ion-content>
    </ion-menu>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, 
  IonLabel,
  IonList,
  IonItem,
  IonMenu,
  IonNote,
  IonIcon,
  IonTitle,
  IonContent,
  IonHeader,
  IonToolbar,
  menuController,
  toastController,
} from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonLabel,
    IonList,
    IonItem,
    IonMenu,
    IonNote,
    IonIcon,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonContent,
  },
  data() {
    return {
      toast: false,
    }
  },
  computed: {
    newVersionAvailable() {
      if(
          this.$store.getters['registry/getLatestVersion'] &&
          this.$store.getters['registry/getLatestVersion'] !== this.$store.getters['appVersion']
      ) {
        return true;
      }
      return false;
    },
    version() {
      return this.$store.getters['appVersion'];
    },
    messages() {
      return this.$store.getters['getMessages'];
    },
  },
  watch: {
    messages(val, oldVal) {
      if (val && val!=oldVal && val.length > 0) {
        this.presentToast();
      }
    }
  },
  methods: {
    actions() {
      menuController.close();
      this.$router.push({path: '/actions'});
    },
    orders() {
      menuController.close();
      this.$router.push({path: '/'});
    },
    abonent() {
      menuController.close();
      this.$router.push({path: '/abonent'});
    },
    register() {
      menuController.close();
      this.$router.push({path: '/register'});
    },
    rework() {
      menuController.close();
      this.$router.push({path: '/rework'});
    },
    update() {
      menuController.close();
      window.open('https://delivery.dryclean.ru/driver.apk');
    },
    logout() {
      menuController.close();
      this.$store.dispatch('logout');
      this.$router.push({path: '/login'});
    },
    async presentToast() {
      if (this.toast) {
        await this.toast.dismiss();
      }
      this.toast = await toastController.create({
        message: this.messages.join("\n<br>"),
        position: 'top',
        buttons: [
          {
            text: 'Закрыть',
            role: 'cancel',
            handler: () => { 
              this.$store.dispatch('clearMessages');
              this.toast.dismiss();
            }
          }
        ]
      });

      await this.toast.present();
    },
  },
});
</script>
import { 
  getRouteService, 
  getOrderService, 
  cancelOrderService, 
  driveOrderService, 
  inplaceOrderService, 
  deliveryOrderService, 
  acceptOrderService,
  openShiftService,
  closeShiftService,
  moveOrderService
} from "../api/order";
import endpoints from "@/api/endpoints";
import {$api} from "@/plugins/Axios";


/**
 * Состояние списка заказов
 */
const state = () => ({
  isLoading: false,
  orders: [],
  specialOrders: [],
  curOrder: {},
  curSlot: {},
  shiftStatus: '',
  routeNum: '',
  driverId: '',
})

const getters = {
  /**
   * Возвращаем отсортированные заказы, разбитые по слотам
   * @param object state 
   * @returns 
   */
   isLoading: (state) => {
    return state.isLoading;
  },
  /**
   * Возвращаем статус рабочей смены
   * @param object state
   * @returns
   */
  getShiftStatus: (state) => {
      return state.shiftStatus;
  },
  /**
   * Возвращаем отсортированные заказы, разбитые по слотам
   * @param object state 
   * @returns 
   */
  getSortedOrders: (state) => {
    let slots = {};
    let deadlineDate = new Date();            
    if (deadlineDate.getTimezoneOffset()!=-3) {
      // конвертируем текущее время в МСК, если еще не сконвертировано
      deadlineDate = new Date((typeof deadlineDate === "string" ? new Date(deadlineDate) : deadlineDate).toLocaleString("en-US", {timeZone: 'Europe/Moscow'}));
    }

    for (let order of state.orders) {
      let slot = {
        'title': order.slot.time,
        'sort': order.slot.sort,
        'key': order.slot.id,
        'active': true,
        'orders': []
      };
      order.active = true;
      order.expired = false;

      if (order.status=='complete' || order.status=='cancel' || order.status=='move' || order.status=='partial') {
        slot.key = slot.key + '/done';
        slot.sort += 24*60;
        slot.active = false;
        order.active = false;
      } else if (order.status=='new') {
        slot.key = slot.key + '/new';
        slot.sort -= 24*60;
        slot.isNew = true;
      } else {
        if (order.deadline*1000 < deadlineDate.getTime() )
        order.expired = true;
      }
      if (!slots[slot.key]) {
        slots[slot.key] = slot;
      }
      slots[slot.key].orders.push(order);
    }

    let result = Object.values(slots);
    result.sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));

    return result;
  },
  /**
   * Возвращаем отсортированные заказы, разбитые по слотам
   * @param object state 
   * @returns 
   */
   getSortedSpecialOrders: (state) => {
    let orders = [];
    for (let order of state.specialOrders) {
      order.active = true;
      order.sort = order.id;

      if (order.status=='complete' || order.status=='cancel' || order.status=='move' || order.status=='partial') {
        order.sort += 24*60;
        order.active = false;
      } 
      orders.push({...order});
    }

    orders.sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));

    return orders;
  },
  /**
   * Возвращаем активные заказы
   * @param object state 
   * @returns 
   */
   getActiveOrders: (state) => {
    let orders = [];
    for (let order of state.orders) {
      if (order.status=='complete' || order.status=='cancel' || order.status=='partial' || order.status=='move') {
        continue;
      }
      orders.push(order);
    }

    return orders;
  },
  /**
   * Получаем слот по ID
   * @param object state
   * @returns
   */
  getOrdersSlot: (state, getters) => id => {
    return getters.getSortedOrders.find(slot => slot.key === id)
  },
  /**
   * Заказы полученые через API
   * @param object state 
   * @returns 
   */
  getOrders: (state) => {
    return state.orders
  },
  /**
   * Особые Заказы полученые через API
   * @param object state 
   * @returns 
   */
   getSpecialOrders: (state) => {
    return state.specialOrders
  },
  /**
   * Получить текущий заказ
   */
  getCurrentOrder: (state) => {
    let order = state.curOrder;
    order.active = true;
    order.expired = false;
    if (order.status=='complete' || order.status=='cancel' || order.status=='partial' || order.status=='move') {
      order.active = false;
    } else {
      let deadlineDate = new Date();            
      if (deadlineDate.getTimezoneOffset()!=-3) {
        // конвертируем текущее время в МСК, если еще не сконвертировано
        deadlineDate = new Date((typeof deadlineDate === "string" ? new Date(deadlineDate) : deadlineDate).toLocaleString("en-US", {timeZone: 'Europe/Moscow'}));
      }
      if (order.deadline*1000 < deadlineDate.getTime() )
      order.expired = true;
    }
    for(let x in order.products) {
      order.products[x].checked = false;
    }
    return order;
  },
  /**
   * Получить товары текущего заказа
   */
  getCurrentOrderProducts: (state) => {
    let order = state.curOrder;
    return [...order.products];
  },
  /**
   * Получить квитанции текущего заказа
   */
  getCurrentOrderInvoices: (state) => {
    let order = state.curOrder;
    return [...order.invoices];
  },
  /**
   * Получить платеж текущего заказа
   */
  getCurrentOrderPayment: (state) => {
    let order = state.curOrder;
    if (order && order.payment) {
      return order.payment;
    }

    return {
      type: '',
      summ: '',
      comment: ''
    };
  },
  /**
   * Получаем все квитанции
   */
  getShiftInvoices: (state) => {
    let invoices = [];
    for (let order of state.orders) {
      if (order.status != 'new') continue;
      for (let invoice of order.invoices) {
        invoices.push({...invoice, orderId: order.id});
      }
    }
    for (let order of state.specialOrders) {
      if (order.status != 'new') continue;
      for (let invoice of order.invoices) {
        invoices.push({...invoice, orderId: order.id});
      }
    }
    return invoices;
  },
  /**
   * Получаем все товары
   */
  getShiftProducts: (state) => {
    let products = [];
    for (let order of state.orders) {
      for (let product of order.products) {
        products.push(product);
      }
    }
    for (let order of state.specialOrders) {
      for (let product of order.products) {
        products.push(product);
      }
    }
    return products;
  },
  /**
   * Получаем реестр приемки
   */
   getRegisterOrders: (state) => {
    let orders = [];
    for (let order of state.orders) {
      if (order.type=='acceptance') {
        orders.push(order);
      }
    }
    for (let order of state.specialOrders) {
      if (order.type=='acceptance' && order.status != 'new') {
        orders.push(order);
      }
    }
    return orders;
  },
  /**
   * Получаем реестр доработки
   */
   getReworkOrders: (state) => {
    let orders = [];
    for (let order of state.orders) {
      if (order.type!='delivery') continue;
      if (!order['invoices'] || order.invoices.length == 0) continue;
      if (order.invoices.filter((item) => {return item.status=='rework';}).length == 0) continue;
      orders.push(order);
    }
    for (let order of state.specialOrders) {
      if (order.type!='delivery') continue;
      if (!order['invoices'] || order.invoices.length == 0) continue;
      if (order.invoices.filter((item) => {return item.status=='rework';}).length == 0) continue;
      orders.push(order);
    }
    return orders;
  },
  /**
   * Провряем выдан ли заказ полностью
   */
   isCompletedCurrentOrder(state) {
    let order = state.curOrder;
    if (!order || !order['invoices']) return false;
    for(let x of order.invoices) {
      if (x.status!='given_away') {
        return false;
      }
    }
    return true;  
  },
  /**
   * номер маршрутного листа
   */
   getRouteNum: (state) => {
    return state.routeNum;
  },
  /**
   * Id водителя
   */
   getDriverId: (state) => {
    return state.driverId;
  },
}

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setShiftStatus(state, shift) {
    state.shiftStatus = shift;
  },


  setOrders(state, orders) {

    let prevOrders = [...state.orders];

    orders = [...orders];
    for (let x in prevOrders) {
      let prevOrder = prevOrders[x];
      for(let y in orders) {
        if (orders[y].id == prevOrder.id) {
          mergeOrders(orders[y], prevOrder);
        }
      }
    }

    state.orders = orders;
  },

  setSpecialOrders(state, orders) {
    state.specialOrders = [...orders];
  },

  setCurrentOrder(state, order) {
    state.curOrder = order;
  },

  setCurrentOrderProductStatus(state, request) {
    let order = state.curOrder;
    for(let x in order.products) {
      if (order.products[x].id==request.id) {
        order.products[x].status = request.status;
      }
    }
    state.curOrder = order;
  },

  setCurrentOrderInvoiceStatus(state, request) {
    let order = state.curOrder;
    for(let x in order.invoices) {
      if (order.invoices[x].id==request.id) {
        order.invoices[x].status = request.status;
      }
    }
    state.curOrder = order;
  },

  setCurrentOrderPayment(state, payment) {
    let order = state.curOrder;
    order.payment = payment;
    state.curOrder = order;
  },

  setPremiumCurrentOrder(state, isPremium) {
    let order = state.curOrder;
    order.isPremium = isPremium;
    state.curOrder = order;
  },

  addCurrentOrderInvoice(state, invoice) {
    let order = state.curOrder;
    invoice.id = new Date();
    invoice.id = invoice.id.getTime();
    order.invoices.push(invoice);
    state.curOrder = order;
  },

  delCurrentOrderInvoice(state, invoice) {
    let order = state.curOrder;
    for(let x in order.invoices) {
      if (order.invoices[x].id==invoice.id) {
        order.invoices.splice(x, 1);
        break;
      }
    }
    state.curOrder = order;
  },

  setRouteNum(state, num) {
    state.routeNum = num;
  },

  setDriverId(state, value) {
    state.driverId = value;
  },

  setInvoice(state, data) {
    let orderId = data.orderId;
    let invoiceId = data.invoiceId;
    let fields = data.fields;
    for (let x in state.orders) {
      if (state.orders[x].id == orderId) {
        for (let y in state.orders[x].invoices) {
          if (state.orders[x].invoices[y].id == invoiceId) {
            for(let code in fields) {
              state.orders[x].invoices[y][code] = fields[code];
            }
          }
        }
      }
    }
    for (let x in state.specialOrders) {
      if (state.specialOrders[x].id == orderId) {
        for (let y in state.specialOrders[x].invoices) {
          if (state.specialOrders[x].invoices[y].id == invoiceId) {
            for(let code in fields) {
              state.specialOrders[x].invoices[y][code] = fields[code];
            }
          }
        }
      }
    }

  }
}

const actions = {
  /**
   * Получение списка заказов
   * @returns 
   */
  async fetchOrders({ commit, dispatch, state }, date) {
    commit('setIsLoading', true);
    try {
      let orders = await getRouteService(date);
      let prevOrders = [...state.orders];
      dispatch('checkForChanges', {orders: orders.orders, prevOrders: prevOrders});
      commit('setOrders', [...orders.orders]);
      commit('setSpecialOrders', [...orders.specialOrders]);
      commit('setIsLoading', false);
      commit('setShiftStatus', orders.shiftStatus);
      commit('setRouteNum', orders.routeNum);
      commit('setDriverId', orders.driverId);
      return orders;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Проверяем не нужно ли отображать сообщение водителю
   * @returns 
   */
  async checkForChanges({ dispatch }, orders) {
    let prevOrders = orders.prevOrders;
    let newOrders = orders.orders;
    let orderExists = true;
    for(let x in prevOrders) {
      orderExists = false;
      for(let y in newOrders) {
        if (newOrders[y].id == prevOrders[x].id) {
          orderExists = true;
          if (newOrders[y].status == 'move' && prevOrders[x].status != 'move') {
            dispatch('addMessage', 'Заказ №' + prevOrders[x].id + ' перенесен', {root: true});
          }
          if (newOrders[y].status == 'cancel' && prevOrders[x].status != 'cancel') {
            dispatch('addMessage', 'Заказ №' + prevOrders[x].id + ' отменен', {root: true});
          }
          continue;
        }
      }
      if (!orderExists && prevOrders[x].status != 'cancel' && prevOrders[x].status != 'move') {
        dispatch('addMessage', 'Заказ №' + prevOrders[x].id + ' перенесен', {root: true});
      }
    }
  },
  /**
   * Получение списка заказов
   * @returns 
   */
  async fetchOrder({ commit }, orderId) {
    commit('setIsLoading', true);
    try {
      commit('setCurrentOrder', {});
      let order = await getOrderService(orderId);
      commit('setCurrentOrder', order);
      commit('setIsLoading', false);
      return order
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Отмена заказа
   */
  async cancelCurrentOrder({ commit, getters, dispatch  }, request) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;
      order = await cancelOrderService(order.id, request);
      commit('setCurrentOrder', order);
      dispatch("fetchOrders", order.date);
      commit('setIsLoading', false);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Перенос заказа
   */
   async moveCurrentOrder({ commit, getters, dispatch  }, request) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;
      order = await moveOrderService(order.id, request);
      commit('setCurrentOrder', order);
      dispatch("fetchOrders", order.date);
      commit('setIsLoading', false);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Переводим заказ в статус поехали
   */
  async driveCurrentOrder({ commit, getters, dispatch  }) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;
      order = await driveOrderService(order.id);
      commit('setCurrentOrder', order);
      dispatch("fetchOrders", order.date);
      commit('setIsLoading', false);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Переводим заказ в статус приехали
   */
   async inplaceCurrentOrder({ commit, getters, dispatch  }) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;
      order = await inplaceOrderService(order.id);
      commit('setCurrentOrder', order);
      dispatch("fetchOrders", order.date);
      commit('setIsLoading', false);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Доставка заказа
   */
  async deliveryCurrentOrder({ commit, getters, dispatch }) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;

      if (!order.payment.type) {
        throw {message: 'Вы не выбрали способ оплаты', 'code': 400};
      }
      let hasDelivery = false;
      for(let invoice of order.invoices) {
        if (invoice.status=='new') {
          invoice.status='not_given';
        }
        if (invoice.status=='given_away' || invoice.status=='rework') {
          hasDelivery = true;
        }
      }
      for(let product of order.products) {
        if (product.status=='given_away') {
          hasDelivery = true;
        }
      }
      if (!hasDelivery) {
        //throw {message: 'Вы не выдали ни одной квитанции и ни одного СТМ', 'code': 400};
      }

      let request = {
        invoices: order.invoices,
        products: order.products,
        payment: order.payment
      };

      order = await deliveryOrderService(order.id, request);
      commit('setCurrentOrder', order);
      commit('setIsLoading', false);
      dispatch("fetchOrders", order.date);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Частичная доставка заказа
   */
   async deliveryPartialCurrentOrder({ commit, getters, dispatch }, params) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;

      if (!order.payment.type) {
        throw {message: 'Вы не выбрали способ оплаты', 'code': 400};
      }
      let hasDelivery = false;
      for(let invoice of order.invoices) {
        if (invoice.status=='new') {
          invoice.status='not_given';
        }
        if (invoice.status=='given_away' || invoice.status=='rework') {
          hasDelivery = true;
        }
      }
      for(let product of order.products) {
        if (product.status=='given_away') {
          hasDelivery = true;
        }
      }
      if (!hasDelivery) {
        throw {message: 'Вы не выдали ни одной квитанции и ни одного СТМ', 'code': 400};
      }
      if (!params || !params.reasonId) {
        throw {message: 'Вы не выбрали причину частичной выдачи', 'code': 400};
      }

      let request = {
        invoices: order.invoices,
        products: order.products,
        payment: order.payment,
        reasonId: params.reasonId,
        status: 'partial'
      };

      order = await deliveryOrderService(order.id, request);
      commit('setCurrentOrder', order);
      commit('setIsLoading', false);
      dispatch("fetchOrders", order.date);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Приемка заказа
   */
  async acceptCurrentOrder({ commit, getters, dispatch }) {
    commit('setIsLoading', true);
    try {
      let order = getters.getCurrentOrder;

      if (!order.payment.type) {
        throw {message: 'Вы не выбрали способ оплаты', 'code': 400};
      }
      if (order.invoices.length==0) {
        throw {message: 'Вы не добавили ни одной квитанции', 'code': 400};
      }

      let request = {
        invoices: order.invoices,
        products: order.products,
        payment: order.payment,
        isPremium: order.isPremium
      };
      if (order['departureType']) {
        request.departureType = order['departureType'];
      }
      for (let x in request.invoices) {
        if (!request.invoices[x].status) {
          request.invoices[x].id = false;
        }
      }
      order = await acceptOrderService(order.id, request);
      commit('setCurrentOrder', order);
      commit('setIsLoading', false);
      dispatch("fetchOrders", order.date);
      return order;
    } 
    finally { commit('setIsLoading', false); }
  },
  setPremiumCurrentOrder({ commit }, isPremium) {
    commit('setPremiumCurrentOrder', isPremium);
    return true;
  },
  /**
   * Изменение статуса товара в заказе
   */
  productStatus({ commit }, request) {
    commit('setCurrentOrderProductStatus', request);
    return true;
  },

  /**
   * Изменение статуса квитанции в заказе
   */
  invoiceStatus({ commit }, request) {
    commit('setCurrentOrderInvoiceStatus', request);
    return true;
  },

  /**
   * Изменение статуса квитанции в заказе
   */
   payment({ commit }, payment) {
    commit('setCurrentOrderPayment', payment);
    return true;
  },
  /**
   * Добавляем новую квитанцию к заказу
   */
  invoiceAdd({ commit }, invoice) {
    commit('addCurrentOrderInvoice', invoice);
    return true;
  },
  /**
   * Удаляем квитанцию из заказа
   */
   invoiceDel({ commit }, invoice) {
    commit('delCurrentOrderInvoice', invoice);
    return true;
  },
  /**
   * Открытие смены
   */
  async shiftOpen({commit}, data) {
    commit('setIsLoading', true);
    try {
      
      let request = {
        invoices: data.invoices,
        products: data.products,
      };

      let shift = false;
      let response = await $api.get(endpoints.orders.timestamp);
      console.log(response.data.result);
      if(response.data.result) {
        shift = await openShiftService(new Date(response.data.result * 1000), request);
        commit('setShiftStatus', shift);
        commit('setIsLoading', false);
      }
      return shift;
    } 
    finally { commit('setIsLoading', false); }
  },
  /**
   * Закрытие смены
   */
  async shiftClose({commit}, data) {
    commit('setIsLoading', true);
    try {
      
      let request = {
        invoices: data.invoices,
        products: data.products,
      };

      let shift = false;
      let response = await $api.get(endpoints.orders.timestamp);
      console.log(response.data.result);
      if(response.data.result) {
        shift = await closeShiftService(new Date(response.data.result * 1000), request);
        commit('setShiftStatus', shift);
        commit('setIsLoading', false);
      }
      return shift;
    } 
    finally { commit('setIsLoading', false); }
  },

  logout({commit}) {
    commit('setShiftStatus','');
    commit('setCurrentOrder',{});
    commit('setOrders',[]);
  },
  /**
   * Задаем тип квитанции
   */
  setInvoice({ commit }, data) {
    commit('setInvoice', data);
    return true;
  },
  /**
   * Получение списка заказов
   * @returns 
   */
  setIsLoading({ commit }, val) {
    commit('setIsLoading', val);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}


function mergeOrders(order, prevOrder) {
  if (!prevOrder['invoices'] || prevOrder['invoices'].length==0) {
    return;
  }

  if (!order['invoices'] || order['invoices'].length==0) {
    order['invoices'] = prevOrder['invoices'];
    return;
  }

  for(let x in prevOrder['invoices']) {
    let prevInvoice = prevOrder['invoices'][x];
    for(let y in order['invoices']) {
      if (prevInvoice.id == order['invoices'][y].id) {
        mergeInvoice(order['invoices'][y], prevInvoice);
      }
    }
  }
}

function mergeInvoice(invoice, prevInvoice) {
  if (!prevInvoice['items'] || prevInvoice['items'].length==0) {
    return;
  }
  if (invoice['items'] && invoice['items'].length > 0) {
    return;
  }
  invoice['items'] = prevInvoice['items'];
  invoice['type'] = prevInvoice['type'];
  invoice['discounts'] = prevInvoice['discounts'];
}